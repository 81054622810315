import {State} from "@/store/state";
import {Project} from "@/models/Project";
import {ProjectFile} from "@/models/ProjectFile";
import {Message} from "@/models/Message";

export type Getters = {
    currentProject(state: State): Project | null;
    currentMessages(state: State): Array<Message> | null;
    currentFiles(state: State): Array<ProjectFile> | null;
    fetchingFiles(state: State): boolean;
}

export const getters: Getters ={
    currentProject: (state: State) => {
        return state.currentProject;
    },
    currentMessages: (state: State) => {
        return state.currentMessages;
    },
    currentFiles: (state: State) => {
        return state.currentFiles;
    },
    fetchingFiles: (state: State) => {
        return state.fetchingFiles;
    }
};
