import {state as modalMessageState} from "@/store/modalMessage/state";
import {state as currentUser} from "@/store/currrentUser/state";
import {state as currentProject} from "@/store/currentProject/state";

export const state = {
    ...modalMessageState,
    ...currentUser,
    ...currentProject
};

export type State = typeof state;
