<template>
  <a class="app-back-link" @click="$router.back">
    <left-outlined />
    <slot>Back</slot>
  </a>
</template>

<script>
import {LeftOutlined} from '@ant-design/icons-vue';

export default {
  name: "BackLink",
  components: {
    LeftOutlined
  }
};
</script>

<style scoped lang="scss">
.app-back-link {
  color: #00AF89;
  font-size: 16px;
  transition: 0.15s linear;

  &:hover {
    color: #008767;
  }
}

</style>
