import {
  createRouter,
  createWebHistory, RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  RouteRecordRaw,

} from "vue-router";

import AppHome from "../views/AppHome.vue";
import AppAboutUS from "../views/AppAboutUS.vue";
import AppServices from "@/views/AppServices.vue";
import AppContact from "@/views/AppContact.vue";
import AppCreateProjectWelcome from "@/views/AppCreateProjectWelcome.vue";
import AppNotFoundPage from "@/views/AppNotFoundPage.vue";
import AppCreateProject from "@/views/AppCreateProject.vue";
import AppProfile from "@/views/AppProfile.vue";
import AppChangePassword from "@/views/AppChangePassword.vue";
import AppProjects from "@/views/AppProjects.vue";
import AppProject from "@/views/AppProject.vue";
import AppPrivacyPolicy from "@/views/AppPrivacyPolicy.vue";
import AppTermsAndConditions from "@/views/AppTermsAndConditions.vue";


const homeTitle = 'HOME - CAD TECHNICIANS';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "AppHome",
    component: AppHome,
    meta:{
      login: false,
      title: homeTitle
    }
  },
  {
    path: "/about-us",
    name: "AppAboutUS",
    component: AppAboutUS,
    meta:{
      login: false,
      title: 'ABOUT US - CAD TECHNICIANS'
    }
  },
  {
    path: "/policy",
    name: "PrivacyPolicy",
    component: AppPrivacyPolicy,
    meta:{
      login: false,
      title: 'Privacy Policy - CAD TECHNICIANS'
    }
  },
  {
    path: "/terms",
    name: "TermsAndConditions",
    component: AppTermsAndConditions,
    meta:{
      login: false,
      title: 'Terms and Conditions - CAD TECHNICIANS'
    }
  },
  {
    path: "/services",
    name: "AppServices",
    component: AppServices,
    meta:{
      login: false,
      title: 'SERVICES - CAD TECHNICIANS'
    }
  },
  {
    path: "/contacts",
    name: "AppContact",
    component: AppContact,
    meta:{
      login: false,
      title: 'CONTACTS - CAD TECHNICIANS'
    }
  },
  {
    path: "/create-project-welcome",
    name: "AppCreateProjectWelcome",
    component: AppCreateProjectWelcome,
    meta:{
      login: true,
      title: 'CREATE PROJECT - CAD TECHNICIANS'
    }
  },
  {
    path: "/create-project",
    name: "AppCreateProject",
    component: AppCreateProject,
    meta:{
      login: true,
      title: 'CREATE PROJECT - CAD TECHNICIANS'
    }
  },
  {
    path: "/profile",
    name: "AppProfile",
    component: AppProfile,
    meta:{
      login: true,
      title: 'PROFILE - CAD TECHNICIANS'
    }
  },
  {
    path: "/change-password",
    name: "AppChangePassword",
    component: AppChangePassword,
    meta:{
      login: true,
      title: 'CHANGE PASSWORD - CAD TECHNICIANS'
    }
  },
  {
    path: "/projects",
    name: "AppProjects",
    component: AppProjects,
    meta:{
      login: true,
      title: 'PROJECTS - CAD TECHNICIANS'
    }
  },
  {
    path: "/project/:id",
    name: "AppProject",
    component: AppProject,
    meta:{
      login: true,
      title: `PROJECT - CAD TECHNICIANS`
    }
  },

    // /?confirm=key

  { path: '/:pathMatch(.*)*',
    name: "AppNotFoundPage",
    component: AppNotFoundPage,
    meta:{
      login: false,
      title: 'NOT FOUND - CAD TECHNICIANS'
    }
  },
];


const scrollBehavior = (to:  RouteLocationNormalized, from: RouteLocationNormalizedLoaded): any => {
    return { top: 0, left: 0 };
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior,
  routes,
});


router.beforeEach((to, from, next) => {
  const pagePermission =  to.matched.some(record => record.meta.login);
  const pageTitle = to.meta.title;
  if(pagePermission && !window.localStorage.getItem('ct-token')) {
    document.title = homeTitle;
    next('/');
  } else {
    document.title = pageTitle;
    next();
  }
});

export default router;
