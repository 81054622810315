
import {Options, Vue} from "vue-class-component";
import {ErrorMessage, Field, Form} from "vee-validate";
import {PropType} from "vue";
import {Project} from "@/models/Project";
import {Message} from "@/models/Message";

import {projectMessageSeen} from "@/services/requests/projects";
import {projectMessageAdd} from "@/services/adapters/projectsAdapter";
import {ActionTypes, MutationTypes} from "@/store/vuex.types";

import env from "@/utils/env";
import Echo from "laravel-echo";
import AppProjectChatBodyMessage from "@/components/pages/project/AppProjectChatBodyMessage.vue";
import {uploadFilesToS3} from "@/services/downloadFromAWS";

const wrappedWindow = window as any;
wrappedWindow.io = require('socket.io-client');

const echo = new Echo({
  broadcaster: 'socket.io',
  host: env.SOCKET,
  path: '/socket.io',
  auth: {
    headers: {
      'Authorization': `Bearer ${window.localStorage.getItem('ct-token')}`
    }
  }
});


@Options({
  name: "AppProjectChatBody",
  components: {
    AppProjectChatBodyMessage,
    Form,
    Field,
    ErrorMessage
  },
  props: {
    showToggleButtons: {
      type: Boolean,
      default: true
    },
    project: {
      type: Object as PropType<Project>
    }
  },
  data() {
    return {
      message: '',
      files: [],
      inputHeight: '0px',
      filesVersion: 1,
      chatLength: 0,
      isLoading: false,
      isMessagesSeenSent: false,
    };
  },
  computed: {
    inputStyle() {
      return {
        'min-height': this.inputHeight
      };
    },
    messageList(): Array<Message> {
      return this.$store.getters.currentMessages;
    },
    disableSubmit(): boolean {
      return (!this.message && !this.files.length) || this.isLoading;
    }
  },
  watch: {
    message() {
      this.resize();
    }
  },
  emits: {
    chatToggle() {
      return true;
    },
  },
  methods: {
    isCurrentUser(id: string): boolean {
      return +id == this.$store.getters.currentUser.id;
    },
    isNewMessage(seen: number, userId: string) {
      return seen === 0 && String(this.$store.getters.currentUser.id) !== userId;
    },
    isWelcomeMessage(message: Message): boolean {
      return String(message.user_id) === "1";
    },
    toggle() {
      this.$emit('chat-toggle');
    },
    async submitMessage() {
      if (this.disableSubmit) {
        return;
      }
      this.isLoading = true;

      const params: Record<string, unknown> = {
        text: this.message
      };

      if (this.files.length) {
        const files = await uploadFilesToS3(this.project.id, this.files);
        if (files.length) {
          params.s3_files = files;
        }
      }

      const response = await projectMessageAdd(this.project.id, params);
      if (response) {
        this.files = [];
        this.message = '';
      }

      this.$emit('start-update-files', this.filesVersion++);
    },
    removeFile(index: number) {
      this.files.splice(index, 1);
    },
    addFiles(event: any) {
      for (let file of Array.from(event.target?.files)) {
        this.files.push(file);
      }
    },
    getFileName(file: File): string[] {
      let result: Array<string> = [];
      const pointPosition = file.name.lastIndexOf('.');
      result[0] = file.name.slice(0, pointPosition);
      result[1] = file.name.slice(pointPosition + 1).toUpperCase();
      return result;
    },
    resize() {
      this.inputHeight = '0px';
      this.inputHeight = `${this.$refs.messageArea.scrollHeight}px`;
    },
    async getMessageList() {
      await this.$store.dispatch(ActionTypes.LOAD_CURRENT_CHAT_MESSAGES, this.project.id);
      const chat = this.$refs.chat;
      chat.scrollTo(0, chat.scrollHeight);
    },
    async getFilesList() {
      await this.$store.dispatch(ActionTypes.LOAD_CURRENT_CHAT_FILES, this.project.id);
    },
    async sendSeen() {
      if (this.messageList.length > this.chatLength && !this.isMessagesSeenSent) {
        this.isMessagesSeenSent = true;
        await projectMessageSeen(this.project?.id);
      }
      this.chatLength = this.messageList.length;
    },
  },
  async created() {
    this.isLoading = true;
    await this.getMessageList();
    this.isLoading = false;
    echo
        .channel(`${env.SOCKET_PREFIX}surveyChatProject.${this.project.id}`)
        .listen('NewMessageEvent', async () => {
          this.isLoading = true;
          await this.getFilesList();
          await this.getMessageList();
          this.isLoading = false;
        })
        .listen('MakeMessagesSeenEvent', async () => {
          this.isMessagesSeenSent = false;
          await this.getMessageList();
        });


  },
  mounted() {
    this.resize();
  },
  beforeUnmount() {
    this.$store.commit(MutationTypes.CURRENT_CHAT_MESSAGES, null);
  }
})
export default class AppProjectChatBody extends Vue {
}
