import {ActionTypes, MutationTypes} from "@/store/vuex.types";
import {Commit} from 'vuex';
import router from "@/router";
import {State} from "@/store/state";
import {authLogin, authCurrentUser} from "@/services/adapters/authAdapter";
import {LoginClient} from "@/models/LoginClient";
import {CurrentUser} from "@/models/CurrentUser";
import store from "@/store";

export type CurrentUserActions<State> = {
    [ActionTypes.LOGIN_CURRENT_USER]:
        ({commit}: { commit: Commit }, payload: LoginClient)  => Promise<boolean>;
    [ActionTypes.LOGOUT]: ({commit}: { commit: Commit }) => Promise<void>;
    [ActionTypes.LOAD_CURRENT_USER]: ({commit}: { commit: Commit }) => Promise<boolean>;
}

export const currentUserActions: CurrentUserActions<State> = {
    [ActionTypes.LOGIN_CURRENT_USER]: async ({commit}: { commit: Commit }, payload: LoginClient): Promise<boolean> => {
        const responce = await authLogin(payload);
        if(responce.token){
            window.localStorage.setItem('ct-token', responce.token);
            store.dispatch( ActionTypes.LOAD_CURRENT_USER);
        } else {
            return false;
        }
        return true;
    },
    [ActionTypes.LOGOUT]: async ({commit}: { commit: Commit }) => {
        commit(MutationTypes.SET_CURRENT_USER, {});
        window.localStorage.removeItem('ct-token');
        router.push('/');
    },
    [ActionTypes.LOAD_CURRENT_USER]: async ({commit}: { commit: Commit }) => {
        const client =  await authCurrentUser() as CurrentUser;
        if (client.id) {
            commit(MutationTypes.SET_CURRENT_USER, client);
            return true;
        }
        return false;
    }
};
