import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ct_modal = _resolveComponent("ct-modal")!

  return (_ctx.showMassage)
    ? (_openBlock(), _createBlock(_component_ct_modal, {
        key: 0,
        id: "message",
        onModalClose: _ctx.closeModal
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "app-message",
            innerHTML: _ctx.alertMessage
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["onModalClose"]))
    : _createCommentVNode("", true)
}