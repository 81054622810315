import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "app-project-chat" }
const _hoisted_2 = {
  key: 0,
  class: "app-project-chat__title"
}
const _hoisted_3 = { class: "app-project-chat__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_project_chat_body = _resolveComponent("app-project-chat-body")!
  const _component_app_project_chat_files = _resolveComponent("app-project-chat-files")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.toggleChat)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, " Chat "))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_3, [
      (_ctx.toggleChat)
        ? (_openBlock(), _createBlock(_component_app_project_chat_body, {
            key: 0,
            "show-toggle-buttons": _ctx.showToggleButtons,
            onChatToggle: _ctx.toggle,
            project: _ctx.project
          }, null, 8, ["show-toggle-buttons", "onChatToggle", "project"]))
        : _createCommentVNode("", true),
      (_ctx.toggleFiles)
        ? (_openBlock(), _createBlock(_component_app_project_chat_files, {
            key: 1,
            "show-toggle-buttons": _ctx.showToggleButtons,
            onChatToggle: _ctx.toggle,
            project: _ctx.project,
            filesUpdateVersion: _ctx.filesUpdateVersion
          }, null, 8, ["show-toggle-buttons", "onChatToggle", "project", "filesUpdateVersion"]))
        : _createCommentVNode("", true)
    ])
  ]))
}