import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/image/logo-dark.svg'


const _hoisted_1 = { class: "chat__user-avatar" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: ""
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "chat__message-wrapper" }
const _hoisted_5 = { class: "chat__message-content" }
const _hoisted_6 = { class: "chat__message-footer" }
const _hoisted_7 = { class: "chat__message-date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_project_chat_files_item = _resolveComponent("app-project-chat-files-item")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['app-message chat__message', {
    'chat__message--welcome': _ctx.isWelcome
  }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isWelcome)
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.avatarImage,
            alt: ""
          }, null, 8, _hoisted_3))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", null, _toDisplayString(_ctx.message.text), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFile(_ctx.message.files), (file) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "attach-files__file attach-files__file--small",
            key: file
          }, [
            _createVNode(_component_app_project_chat_files_item, {
              class: "chat__message--file",
              file: file,
              project: _ctx.project,
              "date-show": false,
              "file-remove": false
            }, null, 8, ["file", "project"])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.message.created_at), 1)
      ])
    ])
  ], 2))
}