import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_navigation_authorized = _resolveComponent("app-navigation-authorized")!
  const _component_app_navigation_guest = _resolveComponent("app-navigation-guest")!
  const _component_toggle_menu = _resolveComponent("toggle-menu")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["app-navigation", { 'desktop-navigation': _ctx.desktopMenu}])
  }, [
    _createVNode(_component_app_navigation_authorized, {
      onShowLoginModal: _ctx.showLoginModal,
      onToggleMenu: _ctx.toggleMenu,
      onToggleDesktopMenu: _ctx.toggleDesktopMenu
    }, null, 8, ["onShowLoginModal", "onToggleMenu", "onToggleDesktopMenu"]),
    _createVNode(_component_app_navigation_guest),
    _createVNode(_component_toggle_menu, {
      class: "app-navigation__menu",
      onHideMenu: _ctx.hideMenu,
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleDesktopMenu(true))),
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleDesktopMenu(false)))
    }, null, 8, ["onHideMenu"])
  ], 2))
}