import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_app_login = _resolveComponent("app-login")!
  const _component_ct_modal = _resolveComponent("ct-modal")!
  const _component_app_registration = _resolveComponent("app-registration")!
  const _component_app_forgot_password = _resolveComponent("app-forgot-password")!
  const _component_app_reset_password = _resolveComponent("app-reset-password")!
  const _component_app_message = _resolveComponent("app-message")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createBlock(_component_a_spin, {
    spinning: _ctx.isSpinner,
    size: "large",
    wrapperClassName: _ctx.isSpinner ? 'app-spinner' : ''
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["app__wrapper", {'app-white-page': _ctx.whitePage, 'app-green-page': _ctx.whitePage === false, 'mobile-nav': _ctx.toggle}])
      }, [
        _createVNode(_component_app_header, {
          onShowLoginModal: _ctx.showLoginModal,
          onShowMainMenu: _ctx.toggleMenu
        }, null, 8, ["onShowLoginModal", "onShowMainMenu"]),
        _createElementVNode("main", _hoisted_1, [
          _createVNode(_component_router_view, {
            onWhitePage: _ctx.isWhitePage,
            onShowRegistrationModal: _ctx.showRegistrationModal,
            onShowLoginModal: _ctx.showLoginModal,
            onShowResetPasswordModal: _ctx.showResetPasswordModal
          }, null, 8, ["onWhitePage", "onShowRegistrationModal", "onShowLoginModal", "onShowResetPasswordModal"])
        ]),
        _createVNode(_component_app_footer, { onShowRegistrationModal: _ctx.showRegistrationModal }, null, 8, ["onShowRegistrationModal"]),
        (_ctx.loginModal)
          ? (_openBlock(), _createBlock(_component_ct_modal, {
              key: 0,
              id: "account-login",
              onModalClose: _ctx.closeAllModal
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_login, {
                  onShowRegistrationModal: _ctx.showRegistrationModal,
                  onShowForgotPasswordModal: _ctx.showForgotPasswordModal,
                  onModalClose: _ctx.closeAllModal
                }, null, 8, ["onShowRegistrationModal", "onShowForgotPasswordModal", "onModalClose"])
              ]),
              _: 1
            }, 8, ["onModalClose"]))
          : _createCommentVNode("", true),
        (_ctx.registrationModal)
          ? (_openBlock(), _createBlock(_component_ct_modal, {
              key: 1,
              id: "account-registration",
              onModalClose: _ctx.closeAllModal
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_registration, {
                  onShowLoginModal: _ctx.showLoginModal,
                  onModalClose: _ctx.closeAllModal
                }, null, 8, ["onShowLoginModal", "onModalClose"])
              ]),
              _: 1
            }, 8, ["onModalClose"]))
          : _createCommentVNode("", true),
        (_ctx.forgotPasswordModal)
          ? (_openBlock(), _createBlock(_component_ct_modal, {
              key: 2,
              id: "account-forgot-password",
              onModalClose: _ctx.closeAllModal
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_forgot_password, { onModalClose: _ctx.closeAllModal }, null, 8, ["onModalClose"])
              ]),
              _: 1
            }, 8, ["onModalClose"]))
          : _createCommentVNode("", true),
        (_ctx.resetPasswordModal)
          ? (_openBlock(), _createBlock(_component_ct_modal, {
              key: 3,
              id: "account-reset-password",
              onModalClose: _ctx.closeAllModal
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_reset_password, {
                  payload: _ctx.payload,
                  onModalClose: _ctx.closeAllModal
                }, null, 8, ["payload", "onModalClose"])
              ]),
              _: 1
            }, 8, ["onModalClose"]))
          : _createCommentVNode("", true),
        _createVNode(_component_app_message)
      ], 2)
    ]),
    _: 1
  }, 8, ["spinning", "wrapperClassName"]))
}