
import { Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage, FormActions } from 'vee-validate';
import CtButton from "@/components/ui/CtButton.vue";
import {MutationTypes} from "@/store/vuex.types";
import {RegistrationClient} from "@/models/RegistrationClient";
import { authRegistration } from "@/services/adapters/authAdapter";
import { isObject } from '@/utils/common';

const fieldNamesDict: Record<string, string> = {
  name: 'company',
  phone: 'phone',
  password: 'password',
  password_confirmation: 'repeatPassword',
  email: 'email'
};

function getFieldNameFromResponse(name: string): string | undefined {
  return fieldNamesDict[name];
}

@Options({
  name: "AppRegistration",
  emits: ['show-login-modal', 'modal-close'],
  data() {
    return{
      registrationForm: {
        email: '',
        password: '',
        repeatPassword: '',
        phone: '',
        company: '',
      } as RegistrationClient,
      isSign: false,
      error: ''
    };
  },
  components: {
    CtButton,
    Form,
    Field,
    ErrorMessage
  },
  methods:{
    showLoginModal() {
      this.$emit('show-login-modal');
    },
    validateEmail(value:string) {
      if (!value) {
        return "This field is required";
      }
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return "This field must be a valid email";
      }
      return true;
    },
    validatePhone(value: string) {
      if (!value) {
        return true;
      }
      const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
      if (!regex.test(value)) {
        return "This field must be a valid phone";
      }
      if (value.length <= 5) {
        return "Phone must contain at least 12 characters";
      }

      return true;
    },
    validateCompany(value: string) {
      if (!value) {
        return "This field is required";
      }
      return true;
    },
    validatePassword(value: string){
      if (!value) {
        return "This field is required";
      }
      if (value.length < 8) {
        return "Password must contain at least 8 characters";
      }
      return true;
    },
    validateRepeatPassword(value: string){
      if (!value) {
        return "This field is required";
      }
      if (value.length < 8) {
        return "Password must contain at least 8 characters";
      }
      if (value != this.registrationForm.password) {
        return "Repeat password and password does not match";
      }
      return true;
    },
    validateIsSing(value: string) {
      if (!value) {
        return "This field is required";
      }
      return true;
    },

    async submitRegistration(_: unknown, actions: FormActions<Record<string, unknown>>) {
      const response = await authRegistration(this.registrationForm);
      if (response.success) {
        this.$store.commit(MutationTypes.SET_ALERT_MESSAGE, response.message);
        this.$emit('modal-close');
        return;
      }
      if (isObject(response.message)) {
        const responseMessage = response.message as Record<string, string[]>;
        this.$store.commit(MutationTypes.SET_ALERT_MESSAGE, Object.values(responseMessage)[0][0]);
        for (let [key, messages] of Object.entries(responseMessage)) {
          const field = getFieldNameFromResponse(key);
          if (field) {
            actions.setFieldError(field, messages.join('\b'));
          }
        }
        return;
      }
      this.$store.commit(MutationTypes.SET_ALERT_MESSAGE, response.message);
    }
  },
})
export default class AppRegistration extends Vue {}
