import {ActionTypes, MutationTypes} from "@/store/vuex.types";
import {Commit} from 'vuex';
import router from "@/router";
import {State} from "@/store/state";

import store from "@/store";
import {Project} from "@/models/Project";


import {projectGetById, projectMessageList, projectFilesList} from "@/services/adapters/projectsAdapter";

export type CurrentProjectActions<State> = {
    [ActionTypes.LOAD_CURRENT_PROJECT]: ({commit}: { commit: Commit }, payload: number) => Promise<void>;
    [ActionTypes.LOAD_CURRENT_CHAT_MESSAGES]: ({commit}: { commit: Commit }, payload: number) => Promise<void>;
    [ActionTypes.LOAD_CURRENT_CHAT_FILES]: ({commit}: { commit: Commit }, payload: number) => Promise<void>;
}

export const currentProjectActions: CurrentProjectActions<State> = {
   [ActionTypes.LOAD_CURRENT_PROJECT]: async ({commit}: { commit: Commit }, payload: number): Promise<void> => {
        const responce = await projectGetById(payload);
        commit(MutationTypes.CURRENT_PROJECT, responce);
    },
    [ActionTypes.LOAD_CURRENT_CHAT_MESSAGES]: async ({commit}: { commit: Commit }, payload: number): Promise<void> => {
        const responce = await projectMessageList(payload);
        responce.reverse();
        commit(MutationTypes.CURRENT_CHAT_MESSAGES, responce);
    },
    [ActionTypes.LOAD_CURRENT_CHAT_FILES]: async ({commit}: { commit: Commit }, payload: number): Promise<void> => {
        commit(MutationTypes.FETCHING_CURRENT_CHAT_FILES, true);
        const responce = await projectFilesList(payload);
        responce.reverse();
        commit(MutationTypes.CURRENT_CHAT_FILES, responce);
        commit(MutationTypes.FETCHING_CURRENT_CHAT_FILES, false);
    }

};
