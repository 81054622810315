
import {Options, Vue} from "vue-class-component";
import {ProjectStatus} from "@/models/ProjectStatus";
import {ErrorMessage, Field, Form} from "vee-validate";
import {PropType} from "vue";
import {Project} from "@/models/Project";
import AppProjectChatBody from "@/components/pages/project/AppProjectChatBody.vue";
import AppProjectChatFiles from "@/components/pages/project/AppProjectChatFiles.vue";

const mainBody = document.querySelector('body');
const maxWidthForMobileMenuView = 1200;

@Options({
  name: "AppProjectChat",
  components: {
    AppProjectChatFiles,
    AppProjectChatBody,
    Form,
    Field,
    ErrorMessage
  },
  data() {
    return{
      toggleChat: true,
      toggleFiles: false,
      showToggleButtons: true,
      filesUpdateVersion: 1,
    };
  },
  props: {
    project: {
      type: Object as PropType<Project>
    }
  },
  emits: {
  },
  methods: {
    toggle() {
      this.toggleFiles = !this.toggleFiles;
      this.toggleChat = !this.toggleChat;
    },
    onResize() {
      if(window.innerWidth >= maxWidthForMobileMenuView) {
        this.showToggleButtons = false;
        this.toggleChat = true;
        this.toggleFiles = true;
        return;
      }
      this.showToggleButtons = true;
      if( this.toggleChat === this.toggleFiles) {
        this.toggleChat = true;
        this.toggleFiles = false;
      }
    },
  },
  beforeUnmount() {
    if (typeof window === 'undefined') return;
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, {passive: true});
  }

})
export default class AppProjectChat extends Vue {
}
