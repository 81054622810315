
import {Options, Vue} from "vue-class-component";
import AppHomeContentCanvas from "@/components/pages/home/AppHomeContentCanvas.vue";

@Options({
  name: "AppHomeContent",
  components: {AppHomeContentCanvas},
  emits: ['show-registration-modal'],
  methods: {
    handleTryIt(){
      if(!this.$store.getters.currentUser.id){
        this.$emit('show-registration-modal');
      } else {
        this.$router.push("/projects");
      }
    }
  }

})
export default class AppHomeContent extends Vue {
}
