
import {Options, Vue} from "vue-class-component";
import {PropType} from 'vue';
import AppProjectsTableHeader from "@/components/pages/project/AppProjectsTableHeader.vue";
import {Project} from "@/models/Project";
import AppProjectsTableItem from "@/components/pages/project/AppProjectsTableItem.vue";
import { ProjectStatus } from '@/models/ProjectStatus';

@Options({
  name: "AppProjectsTable",
  props: {
    projectList: {
      type: Array as PropType<Array<Project>>,
      default: () => []
    },
    totalItems: {
      type: Number,
      default: () => 0
    },
    lastPage: {
      type: Number,
      default: () => 1
    },
    page: {
      type: Number,
      default: () => 1
    },
    perPage: {
      type: Number,
      default: () => 15
    },
    status: {
      type: String as PropType<ProjectStatus | 'ALL'>,
      default: () => 'ALL'
    },
    search: {
      type: String,
      default: () => ''
    }
  },
  components: {
    AppProjectsTableItem,
    AppProjectsTableHeader
  },
  emits: [
    'change:page',
    'change:status',
    'change:search'
  ],
  methods: {
    onChangeStatus(status: ProjectStatus | 'ALL') {
      this.$emit('change:status', status);
    },
    onSearch(value: string) {
      this.$emit('change:search', value);
    },
    onChangePagination(page: number) {
      this.$emit('change:page', page);
    }
  },
  computed: {
    toItem() {
      return (this.page - 1) * this.perPage + this.projectList.length;
    },
    fromItem() {
      return this.toItem - this.projectList.length + 1;
    },
  }
})
export default class AppProjectsTable extends Vue {
}
