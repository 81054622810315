import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-navigation-authorized-true" }
const _hoisted_2 = { class: "app-navigation-authorized-true__name" }
const _hoisted_3 = { class: "app-navigation-authorized-true__logo" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, " HELLO, " + _toDisplayString(_ctx.currentUser.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        src: _ctx.currentUser.logo,
        alt: ""
      }, null, 8, _hoisted_4)
    ])
  ]))
}