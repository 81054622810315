
import {Options, Vue} from "vue-class-component";
import {mapGetters} from "vuex";


@Options({
  name: "AppNavigationAuthorizedTrue",
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {},
})
export default class AppNavigationAuthorizedTrue extends Vue {
}
