
import { Options, Vue } from "vue-class-component";
const Body = document.querySelector('body');
@Options({
  name: "CtModal",
  emits: ['modal-close'],
  components: {
  },
  data() {
    return {
      Body: Object.freeze(Body),
      AppHeader: document.querySelector('.app-header'),
      AppMain: document.querySelector('.app-main'),
    };
  },
  methods: {
    close() {
      this.$emit('modal-close');
      this.Body.classList.remove("bodyNotScroll");
      this.AppHeader.classList.remove("app-header-not-click");
      this.AppMain.classList.remove("app-header-not-click");
    }
  },
  mounted() {
    this.$nextTick(()=> {
      this.Body.classList.add("bodyNotScroll");
      this.AppHeader.classList.add("app-header-not-click");
      if(!this.AppMain) {
        this.AppMain = document.querySelector('.app-main');
      }
      this.AppMain.classList.add("app-header-not-click");
    });
  },
  beforeUnmount() {
    this.Body.classList.remove("bodyNotScroll");
    this.AppHeader.classList.remove("app-header-not-click");
    this.AppMain.classList.remove("app-header-not-click");
  }
})
export default class CtModal extends Vue {}
