import {instanceAxios} from "@/services/axiosInit";
import {RegistrationClient} from "@/models/RegistrationClient";
import {LoginClient} from "@/models/LoginClient";
import {Response} from "@/models/Response";
import {UpdateCurrentUser} from "@/models/UpdateCurrentUser";
import store from "@/store";
import {MutationTypes} from "@/store/vuex.types";
import { AxiosError } from 'axios';

async function authRegistration(registrationForm: any): Promise<Response> {
    try {
        const responce = await instanceAxios.post<Response>('/api/auth/register', registrationForm);
        return responce.data;
    } catch (e) {
        return e.response.data;
    }
}

async function authRegistrationConfirm(key: string) {
        const responce = await instanceAxios.post(`/api/auth/confirm/${key}`) as Response;
        return responce.data;
}

async function toResetPassword(email: string) {
    try{
        const response = await instanceAxios.post(`/api/auth/reset`, {
            email
        }) as Response;
        return response.data;
    } catch(e){
        store.commit(MutationTypes.SET_ALERT_MESSAGE, e.message);
    }
}

interface processResetPasswordInterface {
    email: string,
    token: string,
    password: string,
    password_confirmation: string
}

async function processResetPassword(data: processResetPasswordInterface) {
    try{
        const response = await instanceAxios.post(`/api/auth/reset-password`, {
            ...data
        }) as Response;
        return response.data;
    } catch(e){
        store.commit(MutationTypes.SET_ALERT_MESSAGE, e.message);
    }
}

async function authLogin(loginForm: LoginClient) {
    const responce  = await instanceAxios.post('/api/auth/login', loginForm)  as Response;
    return responce.data;
}

async function authCurrentClient() {
    const responce = await instanceAxios.get('/api/me', ) as Response;
    return responce.data;
}

async function authUpdateCurrentClient(updateCurrentUser: UpdateCurrentUser) {
        const responce = await instanceAxios.post('/api/me', updateCurrentUser) as Response;
        return responce.data;
}

export  {
    authRegistration,
    authRegistrationConfirm,
    authLogin,
    authCurrentClient,
    authUpdateCurrentClient,
    toResetPassword,
    processResetPassword,
};
