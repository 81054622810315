
import {Options, Vue} from "vue-class-component";
import CtModal from "@/components/ui/CtModal.vue";
import {MutationTypes} from "@/store/vuex.types";

@Options({
  name: "AppMessage",
  data() {
    return{
    };
  },
  components: {
    CtModal
  },
  computed:{
    alertMessage (): string {
     return this.$store.getters.alertMessage;
    },
    showMassage(): boolean {
      return this.alertMessage ? true : false;
    }
  },
  methods: {
    closeModal(): void {
      this.$store.commit(MutationTypes.SET_ALERT_MESSAGE, '');
    }
  },
})
export default class AppMessage extends Vue {
}
