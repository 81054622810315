import { GetterTree } from 'vuex';
import { State } from './state';

import { Getters as ModalMessageGetters, getters as modalMessageGetters} from "@/store/modalMessage/getters";
import { Getters as CurrentUserGetters, getters as currentUserGetters} from "@/store/currrentUser/getters";
import { Getters as CurrentProjectGetters, getters as currentProject} from "@/store/currentProject/getters";

export type Getters = ModalMessageGetters & CurrentUserGetters & CurrentProjectGetters; // & ...

export const getters: GetterTree<State, State> & Getters = {
   ...modalMessageGetters,
   ...currentUserGetters,
   ...currentProject
};
