
import {Options, Vue} from "vue-class-component";
import CtButton from "@/components/ui/CtButton.vue";

@Options({
  name: "AppNavigationAuthorizedFalse",
  components: {
    CtButton
  },
  emits: ["show-login-modal"],
  methods: {
    showLoginModal() {
      this.$emit('show-login-modal');
    },
  },
})
export default class AppNavigationAuthorizedFalse extends Vue {}
