
import {Options, Vue} from "vue-class-component";
import AppAboutHeader from "@/components/pages/aboutAs/AppAboutHeader.vue";
import AppAboutInfo from "@/components/pages/aboutAs/AppAboutInfo.vue";
import AppAboutDo from "@/components/pages/aboutAs/AppAboutDo.vue";
import AppAboutWhyUs from "@/components/pages/aboutAs/AppAboutWhyUs.vue";

@Options({
  name: 'AppAboutUS',
  data(){
    return{
      showContent: false,
      showContentResize: false
    };
  },
  computed:{
    showContentCalc(){
      if(this.showContent) return true;
      return this.showContentResize;
    }
  },
  emits: ['white-page', 'show-registration-modal'],
  methods: {
    doShowContent(status: boolean){
      this.showContent = status;
    },
    doShowContentResize(status: boolean){
      this.showContentResize = status;
    },
  },
  components: {
    AppAboutWhyUs,
    AppAboutDo,
    AppAboutInfo,
    AppAboutHeader
  },
  created() {
    this.$emit('white-page', false);
  }
})
export default class AppAboutUS extends Vue {
}
