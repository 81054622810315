import { State } from './state';
import { MutationTree } from 'vuex';

import {ModalMessageMutations, modalMessageMutations} from "@/store/modalMessage/mutations";
import {CurrentUserMutations, currentUserMutation} from "@/store/currrentUser/mutations";
import {CurrentProjectMutations, currentProjectMutation} from "@/store/currentProject/mutations";

export type Mutations<State> = ModalMessageMutations<State> & CurrentUserMutations<State> & CurrentProjectMutations<State>; //&

export const mutations: MutationTree<State> & Mutations<State> = {
    ...modalMessageMutations,
    ...currentUserMutation,
    ...currentProjectMutation
};
