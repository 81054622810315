
import {Options, Vue} from "vue-class-component";
import CtButton from "@/components/ui/CtButton.vue";


@Options({
  name: "AppFooterReady",
  emits: ['show-registration-modal'],
  components: {CtButton},
  methods: {
    redirectToProjects(): void {
      if(!this.$store.getters.currentUser.id){
        this.$emit('show-registration-modal');
      } else {
        this.$router.push("/create-project");
      }
    }
  }
})
export default class AppFooterReady extends Vue {
}
