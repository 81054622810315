
import { Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage } from 'vee-validate';
import CtButton from "@/components/ui/CtButton.vue";
import {MutationTypes} from "@/store/vuex.types";
import {toResetPassword} from '@/services/requests/auth'; // todo: move to adapter

@Options({
  name: "AppForgotPassword",
  data() {
    return {
      email: '',
      error: ''
    };
  },
  emits:['modal-close'],
  components: {
    CtButton,
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    validateEmail(value:string) {
      if (!value) {
        return "This field is required";
      }
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return "This field must be a valid email";
      }
      return true;
    },
    async submitForgotPassword () {
      await toResetPassword(this.email);
      this.$emit('modal-close');
      this.$store.commit(MutationTypes.SET_ALERT_MESSAGE, 'Please check your mail for the password reset information');
    }
  }
})
export default class AppForgotPassword extends Vue {}
