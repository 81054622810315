
import {Options, Vue} from "vue-class-component";


@Options({
  name: "AppAboutInfo",
  components: {},
  emits:{
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    "show-content-resize"(status: boolean){
      return true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    "show-content"(status: boolean){
      return true;
    },
  },
  props: {
    showContent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return{

    };
  },
  methods: {
    onResize() {
      let result = window.innerWidth >= 1400;
      if(result){
        this.$emit('show-content-resize', true);
      }
    },
  },
  beforeUnmount() {
    if (typeof window === 'undefined') return;
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, {passive: true});
  }
})
export default class AppAboutInfo extends Vue {
}
