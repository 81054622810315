
import {Options, Vue} from "vue-class-component";
import {mapGetters} from "vuex";
import {ErrorMessage, Field, Form} from 'vee-validate';
import { authUpdateCurrentUser } from "@/services/adapters/authAdapter";

@Options({
  name: "AppProfilePhone",
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data () {
    return{
      editPhone: false,
      phone: '',
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    disableSaving() {
      return this.phone === this.currentUser.phone;
    }
  },
  emits: {
    'white-page'(payload: boolean) {
      return true;
    }
  },
  methods: {
    toggleEditPhone(){
      if (this.editPhone) {
        this.phone = this.currentUser.phone;
      }
      this.editPhone = !this.editPhone;
    },
    async submitPhone(){
      if (this.disableSaving) {
        return;
      }
      await authUpdateCurrentUser({phone: this.phone});
      this.editPhone = false;
    },
    validatePhone(value: string){
      if (!value) {
        return false;
      }
      const regex = /^[0-9]{4,20}$/i;
      if (!regex.test(value)) {
        return "This field must be a valid phone";
      }
      return true;
    },
    adaptPhoneNumber(numberString: string) {
      if (!numberString){
        return '';
      }
      const prefix = numberString[0] !== '+' ? '+' : '';
      return  prefix + numberString;
    }
  },
  created() {
    this.$emit('white-page', true);
    if (this.currentUser.phone) {
      this.phone = this.currentUser.phone || '';
    } else {
      this.unwatchUser = this.$watch('currentUser.phone', (newVal: string) => {
        this.phone = newVal || '';
        this.unwatchUser();
      });
    }
  }
})
export default class AppProfilePhone extends Vue {
}
