
import {Options, Vue} from "vue-class-component";
import { Form, Field, ErrorMessage } from 'vee-validate';
import CtButton from "@/components/ui/CtButton.vue";
import {mapActions} from "vuex";
import {ActionTypes} from "@/store/vuex.types";
import {LoginClient} from "@/models/LoginClient";


@Options({
  name: "AppLogin",
  emits: ['show-registration-modal', 'show-forgot-password-modal', 'modal-close'],
  data() {
    return {
      loginForm: {
        email: "",
        password: ''
      } as LoginClient,
      error: ''
    };
  },
  components: {
    CtButton,
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    ...mapActions([ActionTypes.LOGIN_CURRENT_USER]),
    showRegistrationModal() {
      this.$emit('show-registration-modal');
    },
    showForgotPasswordModal(){
      this.$emit('show-forgot-password-modal');
    },
    validateEmail(value:string) {
      if (!value) {
        return "This field is required";
      }
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return "This field must be a valid email";
      }
      return true;
    },
    validatePassword(value: string){
      if (!value) {
        return "This field is required";
      }
      if (value.length < 6) {
        return "Password must contain at least 6 characters";
      }
      return true;
    },
    async subminLogin() {
      const state = await this.$store.dispatch(ActionTypes.LOGIN_CURRENT_USER, this.loginForm);
      if(state) { this.$emit('modal-close'); }
    }
  }
})
export default class AppLogin extends Vue {
}
