
import {Options, Vue} from "vue-class-component";
import {Project} from "@/models/Project";
import {PropType} from 'vue';
import {ProjectStatus} from "@/models/ProjectStatus";
import CtModal from "@/components/ui/CtModal.vue";
import { getProjectStatusColor } from '@/utils/project';

@Options({
  name: "AppProjectsTableItem",
  components: {
    CtModal
  },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true
    },
    projectNumber: {
      type: Number,
      required: true
    },
    ProjectStatus
  },
  data() {
    return {
      showModalInfo: false
    };
  },
  computed: {
    hasStatusInfo(): boolean {
      return this.project.status == ProjectStatus.CANCELLATION || this.project.status == ProjectStatus.FROZEN;
    }
  },
  methods: {
    getColorByStatus() {
      return getProjectStatusColor(this.project.status);
    },
    showInfo() {
      this.showModalInfo = true;
    }
  }
})
export default class AppProjectsTableItem extends Vue {
}
