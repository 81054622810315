
import {Options, Vue} from "vue-class-component";
import {PropType} from "vue";
import {Project} from "@/models/Project";
import {ProjectFile} from "@/models/ProjectFile";
import { getSizeString } from '@/utils/ConvertBytes';
import {projectFilesDownload, projectFilesRemove} from '@/services/adapters/projectsAdapter';
import {ActionTypes} from "@/store/vuex.types";

import { getFilesFromServer } from "../../../services/downloadFromAWS";
import {ProjectFileDownload} from "@/models/ProjectFileDownload";

@Options({
  name: "AppProjectChatFilesItem",
  components: {},
  props: {
    project: {
      type: Object as PropType<Project>
    },
    file: {
      type: Object as PropType<ProjectFile>
    },
    dateShow: {
      type: Boolean,
      default: true
    },
    fileRemove:{
      type: Boolean,
      default: true
    },
  },
  computed: {
    canRemoveFiles() {
      const status = this.file.canDelete;
      return status;
    },
    projectDate(): string{
      return this.file.date.toLocaleString().replace(',', '');
    },
    getSize(): string {
      let size = getSizeString(this.file?.size);
      return size;
    }
  },
  methods: {
    getFileName(fileName: string): string[] {
      let result: Array<string> = [];
      const pointPossition = fileName.lastIndexOf('.');
      result[0] = fileName.slice(0, pointPossition);
      result[1] = fileName.slice(pointPossition + 1).toUpperCase();
      return result;
    },
    async downloadFile(){
      const responce =  await projectFilesDownload(this.project.id, [this.file.id]);
      const file = [responce.data[0]] as Array<ProjectFileDownload>;
      await getFilesFromServer(file);
    },
    async removeFile(){
      const responce =  await projectFilesRemove(this.project.id, [this.file.id]);
      await this.$store.dispatch(ActionTypes.LOAD_CURRENT_CHAT_FILES, this.project.id);
    },
  }
})
export default class AppProjectChatFilesItem extends Vue {
}
