
import { ProjectFile } from "@/models/ProjectFile";
import {Options, Vue} from "vue-class-component";
import {PropType} from "vue";
import {Project} from "@/models/Project";
import AppProjectChatFilesItem from "@/components/pages/project/AppProjectChatFilesItem.vue";
import {projectFilesDownload} from "@/services/adapters/projectsAdapter";
import {ActionTypes, MutationTypes} from "@/store/vuex.types";
import {ProjectFileDownload} from "@/models/ProjectFileDownload";
import {getFilesFromServer} from "@/services/downloadFromAWS";

@Options({
  name: "AppProjectChatFiles",
  components: {AppProjectChatFilesItem},
  props: {
    showToggleButtons: {
      type: Boolean,
      default: true
    },
    project: {
      type: Object as PropType<Project>
    },
  },
  computed: {
    files(): Array<ProjectFile>{
      return this.$store.getters.currentFiles;
    },
    fetchingFiles(): boolean {
      return this.$store.getters.fetchingFiles;
    }
  },
  emits: {
    chatToggle() {
      return true;
    }
  },
  methods: {
    toggle() {
      this.$emit('chat-toggle');
    },
    async loadFiles(){
      await this.$store.dispatch(ActionTypes.LOAD_CURRENT_CHAT_FILES, this.project.id);
    },
    async downloadFile(){
      const responce =  await projectFilesDownload(this.project.id, []);
      const files = [] as  Array<ProjectFileDownload>;
      responce.data.forEach((file: ProjectFileDownload) => {
        files.push(file);
      });
      await getFilesFromServer(files);
    },
  },
  async created() {
    await this.loadFiles();
  },
  beforeUnmount() {
    this.$store.commit(MutationTypes.CURRENT_CHAT_FILES, null);
  }
})
export default class AppProjectChatFiles extends Vue {
}
