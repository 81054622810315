
import {Options, Vue} from "vue-class-component";


@Options({
  name: "CtButton",
  components: {},

})
export default class CtButton extends Vue {
}
