
import {Options, Vue} from "vue-class-component";
import AppLogo from "@/components/global/header/AppLogo.vue";
import AppNavigation from "@/components/global/header/AppNavigation.vue";
const mainBody = document.querySelector('body');
const maxWidthForMobileMenuView = 992;
@Options({
  name: "AppHeader",
  emits: {
    "show-login-modal":null,

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'show-main-menu'(state: boolean) {
      return true;
    }
  },
  components: {AppNavigation, AppLogo},
  data() {
    return {
      toggle: false,
      mainBody: Object.freeze(mainBody)
    };
  },
  watch: {
    toggle(){
      if(this.toggle) {
        this.mainBody.classList.add("bodyNotScroll");
      } else {
        this.mainBody.classList.remove("bodyNotScroll");
      }
      this.$emit('show-main-menu', this.toggle);
    },
  },
  methods: {
    mainMenu() {
      window.innerWidth < maxWidthForMobileMenuView ?  this.toggle = true : this.toggle = false;
    },
    hideMenu() {
      this.toggle = false;
    },
    showLoginModal() {
      this.$emit('show-login-modal');
    },
    onResize() {
      if(window.innerWidth >= maxWidthForMobileMenuView) this.toggle = false;
    },
  },
  beforeUnmount() {
    if (typeof window === 'undefined') return;
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, {passive: true});
  }
})
export default class AppHeader extends Vue {
}
