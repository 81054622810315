
import {Options, Vue} from "vue-class-component";
import {PropType} from "vue";
import {Client} from "@/models/Client";

@Options({
  name: "AppFooterClientImage",
  components: {},
  props: {
    client: {
      type: Object as PropType<Client>,
    },
  },
})
export default class AppFooterClientImage extends Vue {
}
