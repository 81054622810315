import {ProjectFileDownload} from "@/models/ProjectFileDownload";
import AWS from "aws-sdk";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileSaver from "file-saver";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import JSZip from 'jszip';

const windowThis = window as any;
windowThis.saveAs = FileSaver.saveAs;

function setupAWS(){
    windowThis.AWS.config.region = 'eu-central-1';
    windowThis.AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'eu-central-1:ea98ca0a-4fb4-4b00-9e79-239fb9ab1b32'
    });
    windowThis.AWS.config.credentials.get(function (err: Error) {
        if (err) alert(err);
    });
}

function getFilesFromServer(files: Array<ProjectFileDownload>) {
    if (files.length) {
        const zip = new JSZip();
        if (files.length === 1) {
            const file = files[0];
            const promise = AWSFileSaver(file);
            promise.then(function (objectData: any) {
                const blob = new Blob([objectData.content], {type: file.mime_type});
                windowThis.saveAs(blob, file.name);
            });
        } else {
            const promises = [] as Array<any>;
            files.map(function (file) {
                promises.push(AWSFileSaver(file));
            });

            Promise.all(promises)
                .then(function (results) {
                    let count = 2;
                    for (const index in results) {
                        const data = results[index];
                        let fileName = data.name;

                        if (getExt(data.name) === '') {
                            fileName = data.name + '.' + data.extension;
                        }
                        const currentFilesInZip = Object.keys(zip.files);
                        if (currentFilesInZip.includes(fileName)) {
                            fileName = count + '__' + fileName;
                            count++;
                        }
                        zip.file(fileName, data.content);
                    }
                    zip.generateAsync({type: "blob"}).then(function (blob: Blob) {
                        windowThis.saveAs(blob, `CT-project-${files[0].project_id}` + ".zip");
                    });
                })
                .catch(function (error: Error) {
                    console.log(error);
                });
        }
    }
}

async function uploadOneFile(projectID: string, file: File, s3: AWS.S3): Promise<Record<string, unknown> | null> {
    const bucketName = 'survey.drafter.storage.eu';
    const key = process.env.VUE_APP_BUCKET_PREFIX + 'projects/';
    const path = `${projectID}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${file.name}`;

    const params = {
        Bucket: bucketName,
        Key: key + path,
        ACL: 'public-read',
        Body: file,
        ContentType: file.type
    };

    const options = {
        partSize: 1000 * 1024 * 1024,
        queueSize: 1
    };

    let isError = false;

    await s3
        .upload(params, options)
        .promise()
        .catch(() => {
            isError = true;
        });

    if (isError) {
        return null;
    }

    return {'path': path, 'name': file.name, 'extension': getExt(file.name), 'mime': file.type, 'size': file.size};
}

async function uploadFilesToS3(projectID: string, files: Array<File>): Promise<Array<Record<string, unknown>>> {
    const filesJson = [];
    setupAWS();

    const s3 = new AWS.S3({});

    for (let i = 0; i < files.length; i++) {
        const result = await uploadOneFile(projectID, files[i], s3);
        if (result) {
            filesJson.push(result);
        }
    }

    return filesJson;
}

function getExt(filename: string) {
    const ext = filename.split('.').pop();
    if (ext === filename) return '';
    return ext;
}

function AWSFileSaver(file: ProjectFileDownload, blob?: Blob) {

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setupAWS();

    const bucketName = 'survey.drafter.storage.eu';
    const key = process.env.VUE_APP_BUCKET_PREFIX + 'projects/' + file.path;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const s3 = new AWS.S3({
        params: {
            Bucket: bucketName,
            Key: key
        }
    });

    return new Promise(function (success, reject) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        s3.getObject(function (err, data) {

            if (err) {
                reject(err);
            } else {
                success({
                    name: file.name,
                    content: data.Body,
                    mime_type: file.mime_type,
                    extension: file.extension
                });
            }
        });
    });
}

export {
    getFilesFromServer,
    uploadFilesToS3,
};
