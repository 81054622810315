
import {Options, Vue} from "vue-class-component";
import {mapGetters} from "vuex";
import AppProfileEmail from "@/components/pages/profile/AppProfileEmail.vue";
import AppProfilePhone from "@/components/pages/profile/AppProfilePhone.vue";

@Options({
  name: "AppProfile",
  components: {AppProfilePhone, AppProfileEmail},
  computed: {
    ...mapGetters(['currentUser']),
  },
  emits: {
    'white-page'(payload: boolean) {
      return true;
    }
  },
  created() {
    this.$emit('white-page', true);
  }
})
export default class AppProfile extends Vue {
}
