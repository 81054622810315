import {Project} from "@/models/Project";
import {ProjectFile} from "@/models/ProjectFile";
import {Message} from "@/models/Message";

export const state = {
    currentProject: {} as Project | null,
    currentMessages: [] as Array<Message> | null,
    currentFiles: [] as Array<ProjectFile> | null,
    fetchingFiles: false
};

export type State = typeof state;
