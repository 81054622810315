import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/image/toggle.svg'


const _hoisted_1 = { class: "toggle-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("img", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args))),
      src: _imports_0,
      alt: "toggle main menu"
    })
  ]))
}