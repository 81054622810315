
import {Options, Vue} from "vue-class-component";
import {ErrorMessage, Field, Form} from 'vee-validate';
import CtButton from "@/components/ui/CtButton.vue";
import {authUpdateCurrentUser} from "@/services/adapters/authAdapter";


let regularExpression  = /^[a-zA-Z0-9!@#_$%^&*]{8,16}$/;
@Options({
  name: "AppChangePassword",
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: ''
    };
  },
  components: {
    CtButton,
    Form,
    Field,
    ErrorMessage
  },
  emits: {
    'white-page'(payload: boolean) {
      return true;
    }
  },
  methods: {
    async changePassword(){
      const state = await authUpdateCurrentUser({
        password: this.newPassword,
        password_confirmation: this.repeatNewPassword
      });
      this.$router.push({path: '/profile'});
    },
    validatePassword(value: string){
      if (!value) {
        return "This field is required";
      }
      if (value.length < 6) {
        return "Password must contain at least 8 characters";
      }
      return true;
    },
    validateRepeatPassword(value: string){
      if (!value) {
        return "This field is required";
      }
      if(value !== this.newPassword){
        return "Password mismatch";
      }
      if (value.length < 6) {
        return "Password must contain at least 8 characters";
      }
      return true;
    },
  },
  created() {
    this.$emit('white-page', true);
  }
})
export default class AppChangePassword extends Vue {
}
