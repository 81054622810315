
import {Options, Vue} from "vue-class-component";
import {PropType} from "vue";
import {Message} from "@/models/Message";

import env from "@/utils/env";
import AppProjectChatFilesItem from "@/components/pages/project/AppProjectChatFilesItem.vue";
import {Project} from "@/models/Project";
import {ProjectFile} from "@/models/ProjectFile";
import { mapGetters } from 'vuex';

@Options({
  name: "AppProjectChatBodyMessage",
  components: {AppProjectChatFilesItem},
  data() {
    return {
      logo: env.logo,
      seenObserver: null,
    };
  },
  props: {
    message: {
      type: Object as PropType<Message>
    },
    project: {
      type: Object as PropType<Project>
    },
    isWelcome: {
      type: Boolean,
      default: false
    },
    chatEl: {
      type: Object as PropType<HTMLElement>,
      required: true
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    messageDate(): string {
      return this.message?.created_at.toLocaleString().replace(',', '');
    },
    avatarImage() {
      if (this.message.avatar && this.message.avatar.startsWith('http')) {
        return this.message.avatar;
      }
      return this.logo;
    },
  },
  methods: {
    getFile(filesList: Array<Record<string,unknown>>) {
      const messageFilesIDs = filesList.map((file) => file.id);
      const files = this.$store.getters.currentFiles as Array<ProjectFile> | null;
      if (!files) return [];
      const result = files.filter(
          (file: ProjectFile) => {
            for (const id of messageFilesIDs) {
              if (file.id === id) return true;
            }
          }
      );
      return result;
    },
  }
})
export default class AppProjectChatBodyMessage extends Vue {
}
