
import {Options, Vue} from "vue-class-component";
import CtButton from "@/components/ui/CtButton.vue";

@Options({
  name: "AppAboutWhyUs",
  components: {
    CtButton
  },
  emits: ['got-started'],
  methods: {
    getStarted() {
      if (this.$store.state.currentUser?.id) {
        this.$router.push('/create-project');
        return;
      }
      this.$emit('got-started');
    }
  }
})
export default class AppAboutWhyUs extends Vue {
}
