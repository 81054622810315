
import {Options, Vue} from "vue-class-component";

@Options({
  name: "AppAboutHeader",
  components: {
  },
})
export default class AppAboutHeader extends Vue {
}
