
import {Options, Vue} from "vue-class-component";
import CtButton from "@/components/ui/CtButton.vue";
import {ErrorMessage, Field, Form} from 'vee-validate';
import {InfoCircleOutlined, CloseOutlined} from '@ant-design/icons-vue';
import {Project} from "@/models/Project";
import {ProjectStatus} from "@/models/ProjectStatus";
import {ProjectFileType} from "@/models/ProjectFileType";
import {MutationTypes} from "@/store/vuex.types";
import {projectCreate, projectFilesUpload} from "@/services/adapters/projectsAdapter";
import {convertBytesToMB} from '@/utils/ConvertBytes';
import {uploadFilesToS3} from "@/services/downloadFromAWS";

@Options({
  name: "AppCreateProject",
  components: {
    CtButton,
    Form,
    Field,
    ErrorMessage,
    InfoCircleOutlined,
    CloseOutlined,
  },
  data() {
    return {
      active: false,
      newProjectForm: {
        name: '',
        address: '',
        description: '',
        files: [] as File[],
        status: ProjectStatus.NEW_PROJECT,
        created: new Date()
      } as Project,
      ProjectStatus,
      ProjectFileType
    };
  },
  emits: {
    'white-page'(payload: boolean) {
      return true;
    }
  },
  computed: {
    totalFilesSize() {
      return this.newProjectForm.files.reduce((total: number, file: File) => {
        if (file) {
          return total + file.size;
        }
        return total;
      }, 0);
    },
    disableUpload() {
      return this.newProjectForm.files.length >= 15 || convertBytesToMB(this.totalFilesSize) >= 500;
    }
  },
  methods: {
    validateAddress(value: string) {
      return value ? !!value : 'The address is required';
    },
    validateName(value: string) {
      if (!value) {
        return 'The name is required';
      }
      if (value.length > 35) {
        return 'The name can not be longer than 35 characters';
      }
      return true;
    },
    checkFileSize(file: File) {
      const isFileBig = convertBytesToMB(this.totalFilesSize + file.size) >= 500;
      if (isFileBig) {
        this.$store.commit(MutationTypes.SET_ALERT_MESSAGE, `The uploaded file is over 500 MB.<br>Please upload a file up to 500 MB.`);
        return null;
      }
      return file;
    },
    processFiles(files: File[]) {
      files.forEach((item: File) => {
        if (this.checkFileSize(item)) {
          this.pushFile(item);
        }
      });
    },
    handleFileInput(e: InputEvent) {
      if (this.disableUpload) return;
      const target = e.target as any;
      this.processFiles(Array.from(target.files));
    },
    removeFile(index: number) {
      this.newProjectForm.files.splice(index, 1);
    },
    pushFile(file: File) {
      if (this.disableUpload) return;
      this.newProjectForm.files.push(file);
    },
    async submitNewProject() {
      const result = await projectCreate(this.newProjectForm, this.newProjectForm.files);
      if (result) {
        const files = await uploadFilesToS3(result.data.id, this.newProjectForm.files);
        if (files.length) {
          await projectFilesUpload(result.data.id, files);
        }
        this.$store.commit(MutationTypes.SET_ALERT_MESSAGE, 'You created new Project!');
        await this.$router.push({
          name: 'AppProject',
          params: {
            id: result.data.id
          }
        });
      }
    },
    handleDragFiles(e: DragEvent) {
      if (this.disableUpload) return;
      this.active = !this.active;
      const files = e.dataTransfer?.files || [];
      this.processFiles(Array.from(files));
    }
  },
  created() {
    this.$emit('white-page', true);
  },
})
export default class AppCreateProject extends Vue {
}
