
import {Options, Vue} from "vue-class-component";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {canvasApp} from "@/utils/canvas";

@Options({
  name: "AppHomeContentCanvas",
  data() {
    return{
      renderId: 1
    };
  },
  components: {},
  methods: {
  },
  mounted() {
    canvasApp();
  },
  unmounted() {
    //
  }
})
export default class AppHomeContentCanvas extends Vue {
}
