import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-about-as" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_about_header = _resolveComponent("app-about-header")!
  const _component_app_about_info = _resolveComponent("app-about-info")!
  const _component_app_about_do = _resolveComponent("app-about-do")!
  const _component_app_about_why_us = _resolveComponent("app-about-why-us")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_about_header),
    _createVNode(_component_app_about_info, {
      "show-content": _ctx.showContentCalc,
      onShowContent: _ctx.doShowContent,
      onShowContentResize: _ctx.doShowContentResize
    }, null, 8, ["show-content", "onShowContent", "onShowContentResize"]),
    _createVNode(_component_app_about_do),
    _createVNode(_component_app_about_why_us, {
      onGotStarted: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('show-registration-modal')))
    })
  ]))
}