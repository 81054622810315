//import store from "@/store";
//import {ActionTypes, MutationTypes} from "@/store/vuex.types";
import {Response} from "@/models/Response";

import {
    projectCreate as createProject,
    projectGetAll as getAllProjects,
    projectGetById as getProjectById,
    projectMessageListGetById as getMessageListById,
    projectMessageAdd as addMessage,
    projectFilesList as getAllFiles,
    projectFilesUpload as uploadFiles,
    projectFilesDownload as downloadFiles,
    projectFilesRemove as removeFiles,
    projectMessageSeen as seenMessages
} from "@/services/requests/projects";
import {Project, ProjectsWithPagination} from "@/models/Project";
import store from "@/store";
import {MutationTypes} from "@/store/vuex.types";
import {ProjectStatus} from "@/models/ProjectStatus";
import {ProjectFile} from "@/models/ProjectFile";
import {ProjectQueries} from '@/models/ProjectQueries';
import {Message} from '@/models/Message';

async function projectCreate(projectForm: Project, files?: File[]) {
    const formData = new FormData() as FormData;
    if (files?.length) {
        for (let i = 0; i < files.length; i++) {
            formData.append('files[]', files[i], files[i].name);
        }
    }
    formData.append('name', projectForm.name);
    formData.append('address', projectForm.address);
    formData.append('description', projectForm.description);


    return await createProject(formData) as Response;
}

async function projectGetAll(params: ProjectQueries = {}): Promise<ProjectsWithPagination | undefined> {
    const response = await getAllProjects(params);
    if (response.data?.projects) {
        const projects: Array<Project> = [];
        for (const item of response.data?.projects) {
            const project = {
                id: item.id,
                name: item.name,
                address: item.address,
                description: item.description,
                created: new Date(item.created_at),
                status: searchStatus(item.status),
                unreadMessages: item.unread_messages
            } as Project;

            projects.push(project);
        }
        return {
            ...response.data,
            projects
        };
    }
    if (!response.success) {
        store.commit(MutationTypes.SET_ALERT_MESSAGE, response.message);
    }
}

function searchStatus(status: string) {
    const keys = Object.keys(ProjectStatus).filter((x) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return ProjectStatus[x] === status.toUpperCase();
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return ProjectStatus[keys[0]];
}

async function projectGetById(id: number) {
    const responce = await getProjectById(id) as Response;
    if (!responce.success) {
        store.commit(MutationTypes.SET_ALERT_MESSAGE, responce.message);
        return null;
    }
    const project = {
        id: responce.data.id as number,
        name: responce.data.name as string,
        address: responce.data.address as string,
        description: responce.data.description as string,
        created: new Date(responce.data.created_at),
        status: searchStatus(responce.data.status)
    } as Project;

    return project;
}

async function projectMessageList(id: number): Promise<Message[]> {
    const responce = await getMessageListById(id);
    if (!responce.success) {
        store.commit(MutationTypes.SET_ALERT_MESSAGE, responce.message);
    }
    return responce.data.map((msg) => ({
        ...msg,
        seen: Number(msg.seen),
        soft_deleted: Number(msg.soft_deleted),
    }));
}

async function projectMessageAdd(id: number, params: Record<string, unknown>): Promise<boolean> {
    const responce = await addMessage(id, params);
    if (responce.success) {
        return true;
    } else {
        store.commit(MutationTypes.SET_ALERT_MESSAGE, responce.message);
        return false;
    }
}

async function projectMessageSeen(id: number): Promise<Response<[]>> {
    const responce = await seenMessages(id);
    if (!responce.success) {
        store.commit(MutationTypes.SET_ALERT_MESSAGE, responce.message);
    }
    return responce;
}


async function projectFilesList(id: number) {
    const responce = await getAllFiles(id) as Response;
    if (!responce.success) {
        store.commit(MutationTypes.SET_ALERT_MESSAGE, responce.message);
        return [];
    }

    const files = [] as Array<ProjectFile>;

    for (const item of responce.data) {
        const file = {
            name: item.name as string,
            canDelete: item.canDelete as boolean,
            extension: item.extension as string,
            projectId: item.project_id as string,
            date: new Date(item.created_at),
            id: item.id as number,
            size: +item.size,
            userId: item.user.id as number
        } as ProjectFile;
        files.push(file);
    }
    return files;
}

async function projectFilesUpload(id: number, filesJson: Array<Record<string, unknown>>) {
    const responce = await uploadFiles(id, filesJson) as Response;
    if (!responce.success) {
        store.commit(MutationTypes.SET_ALERT_MESSAGE, responce.message);
        return [];
    }
}

async function projectFilesDownload(id: number, files: Array<number>) {
    const responce = await downloadFiles(id, files);
    if (!responce.success) {
        store.commit(MutationTypes.SET_ALERT_MESSAGE, responce.message);
        return [];
    }
    return responce;
}

async function projectFilesRemove(id: number, files: Array<number>) {
    const responce = await removeFiles(id, files);
    if (!responce.success) {
        store.commit(MutationTypes.SET_ALERT_MESSAGE, responce.message);
    }
    return responce;
}

export {
    projectCreate,
    projectGetAll,
    projectGetById,
    projectMessageList,
    projectMessageAdd,
    projectMessageSeen,
    projectFilesList,
    projectFilesUpload,
    projectFilesDownload,
    projectFilesRemove
};
