
import {Options, Vue} from "vue-class-component";
import AppServicesHeader from "@/components/pages/services/AppServicesHeader.vue";
import AppServicesList from "@/components/pages/services/AppServicesList.vue";

@Options({
  name: "AppServices",
  emits: {
    'white-page'(payload: boolean) {
      return true;
    }
  },
  components: {
    AppServicesList,
    AppServicesHeader
  },
  created() {
    this.$emit('white-page', false);
  }
})
export default class AppServices extends Vue {
}
