
import {Options, Vue} from "vue-class-component";
import UserMenu from "@/components/global/header/UserMenu.vue";
import AppLogo from "@/components/global/header/AppLogo.vue";
import MainMenu from "@/components/global/header/MainMenu.vue";
import {mapGetters} from "vuex";

@Options({
  name: "ToggleMenu",
  emits: ['hide-menu'],
  components: {MainMenu, AppLogo, UserMenu},
  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn']),
  },
  methods: {
    hideToggleMenu() {
      this.$emit('hide-menu');
    }
  }
})
export default class ToggleMenu extends Vue {
}
