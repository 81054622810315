
import {Options, Vue} from "vue-class-component";
import AppFooterQuestionForm from "@/components/global/footer/AppFooterQuestionForm.vue";

@Options({
  name: "AppFooterQuestion",
  components: {
    AppFooterQuestionForm
  },
})
export default class AppFooterQuestion extends Vue {
}
