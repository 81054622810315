import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-contact" }
const _hoisted_2 = {
  class: "app-contact__question",
  "data-aos": "slide-right",
  "data-aos-duration": "500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_contact_header = _resolveComponent("app-contact-header")!
  const _component_app_footer_question = _resolveComponent("app-footer-question")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_contact_header),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_footer_question)
    ])
  ]))
}