
export enum ActionTypes {
    // current user
    LOGIN_CURRENT_USER='loginCurrentUser',
    LOGOUT='logOut',
    LOAD_CURRENT_USER='loadCurrentUser',

    //current project
    LOAD_CURRENT_PROJECT='loadCurrentProject',
    LOAD_CURRENT_CHAT_MESSAGES = 'loadCurrentChatMessages',
    LOAD_CURRENT_CHAT_FILES = 'loadCurrentChatFiles',

};

export enum MutationTypes {
    // modal Message
    SET_ALERT_MESSAGE = 'setAlertMessage',

    // current user
    SET_CURRENT_USER = 'setCurrentUser',

    // spinner
    ON_SPINNER = 'onSpinner',
    OFF_SPINNER = 'offSpinner',

    //current project
    CURRENT_PROJECT = "loadProject",
    CURRENT_CHAT_MESSAGES = "currentChatMessages",
    CURRENT_CHAT_FILES = "currentChatFiles",
    FETCHING_CURRENT_CHAT_FILES = 'fetchingCurrentChatFiles'
}
