
import {Options, Vue} from "vue-class-component";
import {PropType} from "vue";
import {Client} from "@/models/Client";
import AppFooterClientImage from "@/components/global/footer/AppFooterClientImage.vue";

@Options({
  name: "AppFooterClients",
  components: {
    AppFooterClientImage
  },
  props: {
    clients: {
      type: Array as PropType<Array<Client>>,
    },
  },
})
export default class AppFooterClients extends Vue {
}
