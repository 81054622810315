import { RegistrationClient } from "@/models/RegistrationClient";
import {LoginClient} from "@/models/LoginClient";
import {Response} from "@/models/Response";
import {ActionTypes, MutationTypes} from "@/store/vuex.types";
import store from "@/store";

import env from "@/utils/env";

// api
import { authRegistration as  registration} from "@/services/requests/auth";
import { authRegistrationConfirm as  registrationConfirm} from "@/services/requests/auth";
import { authLogin as  login} from "@/services/requests/auth";
import { authCurrentClient as  currentClient} from "@/services/requests/auth";
import { authUpdateCurrentClient as  updateCurrentClient} from "@/services/requests/auth";


import {CurrentUser} from "@/models/CurrentUser";
import {UpdateCurrentUser} from "@/models/UpdateCurrentUser";

async function authRegistration(registrationForm: RegistrationClient): Promise<Response> {
    const registrationData = {
        name: registrationForm.company,
        surname: env.SURNAME,
        phone: registrationForm.phone,
        password: registrationForm.password,
        password_confirmation: registrationForm.repeatPassword,
        email: registrationForm.email
    };

    const response = await registration(registrationData);
    if(response.success){
        return {
            success: true,
            data: null,
            message: 'We have sent instructions on how to confirm your mail to your e-mail.'
        };
    } else {
        return response;
    }
}

async function authRegistrationConfirm(key: string) {
    const responce = await registrationConfirm(key) as Response;
    return responce.success;
}


async function authLogin(loginForm: LoginClient) {
    const responce = await login(loginForm) as Response;
    if(responce.success){
        return responce.data;
    } else {
        store.commit(MutationTypes.SET_ALERT_MESSAGE, responce.message);
        return responce;
    }
}


async function authCurrentUser() {
    const responce = await currentClient() as Response;
    if(responce.success){
        const client: CurrentUser  ={
            id: responce.data.id,
            name: responce.data.name,
            logo: env.logo as string,
            phone: responce.data.phone,
            email: responce.data.email,
        };
        return client;
    } else {
        store.commit(MutationTypes.SET_ALERT_MESSAGE, responce.message);
    }
}

async function authUpdateCurrentUser(updateCurrentUser: UpdateCurrentUser) {
    const responce = await updateCurrentClient(updateCurrentUser) as Response;
    if(responce.success){
        store.dispatch(ActionTypes.LOAD_CURRENT_USER);
        return true;
    } else {
        store.commit(MutationTypes.SET_ALERT_MESSAGE, responce.message);
        return false;
    }
}

export {
    authRegistration,
    authRegistrationConfirm,
    authLogin,
    authCurrentUser,
    authUpdateCurrentUser
};
