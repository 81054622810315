
import {Options, Vue} from "vue-class-component";

@Options({
  name: "AppNotFoundPage",
  components: {},
  emits: {
    'white-page'(payload: boolean) {
      return true;
    }
  },
  created() {
    this.$emit('white-page', true);
  }
})
export default class AppNotFoundPage extends Vue {
}
