export enum ProjectFileType{
    PDF='pdf',
    TXT='txt',
    DWG='dwg',
    DOC='doc',
    DOCX='docx',
    JPG='jpg',
    PNG='png',
    BPM='bmp',
}
