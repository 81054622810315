
import {Options, Vue} from "vue-class-component";
import {Project} from "@/models/Project";
import {ProjectStatus} from "@/models/ProjectStatus";
import AppProjectChat from "@/components/pages/project/AppProjectChat.vue";

import { ActionTypes, MutationTypes} from "@/store/vuex.types";
import BackLink from '@/components/global/BackLink.vue';

@Options({
  name: "AppProject",
  components: {
    BackLink,
    AppProjectChat,
  },
  data() {
    return {
      ProjectStatus,
      toggleDesc: true,
      message: '',
      files: [],
      formData: {} as FormData
    };
  },
  computed: {
    hasStatusInfo(): boolean {
      return this.project.status == ProjectStatus.CANCELLATION || this.project.status == ProjectStatus.FROZEN;
    },
    formatStatus(): string{
      let result = this.project.status?.toLowerCase();
      result = result[0].toUpperCase() + result.slice(1);
      return result;
    },
    project(): Project {
      return this.$store.getters.currentProject;
    }
  },

  emits: {
    'white-page'(payload: boolean) {
      return true;
    }
  },
  methods: {
    getColorByStatus(): string {
      const values: any = {
        [ProjectStatus.NEW_PROJECT]: '#00AF89',
        [ProjectStatus.CHECKING]: '#098568',
        [ProjectStatus.IN_DEVELOPMENT]: '#025F49',
        [ProjectStatus.COMPLETED]: '#00372A',
        [ProjectStatus.CANCELLATION]: '#8D8D8D',
        [ProjectStatus.FROZEN]: '#FF1616'
      };
      return values[this.project.status] as string || '#9B9B9B';
    },
  },
  async created() {
    await this.$store.dispatch(ActionTypes.LOAD_CURRENT_PROJECT, this.$route.params.id);
    this.$emit('white-page', true);
  },
  beforeUnmount() {
    this.$store.commit(MutationTypes.CURRENT_PROJECT, null);
  }
})
export default class AppProject extends Vue {
}
