import {State} from "@/store/state";


export type Getters = {
    alertMessage(state: State): string;
    spinner(state: State): boolean;
}

export const getters: Getters ={
    alertMessage: (state: State) => {
        return state.alertMessage;
    },
    spinner:(state: State) => {
        return state.spinner;
    }
};
