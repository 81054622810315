import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import aos from 'aos';
import 'aos/dist/aos.css';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import "@/assets/style/main.scss";
import AppFooterQuestion from '@/components/global/footer/AppFooterQuestion.vue';

aos.init({
    disable: 'mobile',
    delay: 300,
    once: true,
});
const app = createApp(App);

// Fix for the component which is not being resolved for some reason
app.component('app-footer-question', AppFooterQuestion);

app.use(aos);
app.use(store).use(router).use(Antd).mount("#app");
