
import {Options, Vue} from "vue-class-component";
import AppHeader from "@/components/global/header/AppHeader.vue";
import AppFooter from "@/components/global/footer/AppFooter.vue";
import CtModal from "@/components/ui/CtModal.vue";
import AppLogin from "@/components/modelComponents/AppLogin.vue";
import AppRegistration from "@/components/modelComponents/AppRegistration.vue";
import AppForgotPassword from "@/components/modelComponents/AppForgotPassword.vue";
import AppResetPassword from "@/components/modelComponents/AppResetPassword.vue";
import AppMessage from "@/components/modelComponents/AppMessage.vue";
import {ActionTypes} from "@/store/vuex.types";

interface PayloadInterface {
  [k: string]: string
}

@Options({
  name: 'App',
  data() {
    return {
      loginModal: false,
      registrationModal: false,
      forgotPasswordModal: false,
      resetPasswordModal: false,
      whitePage: undefined,
      toggle: false,
      payload: {} as PayloadInterface
    };
  },
  computed: {
    isSpinner() {
      return this.$store.getters.spinner;
    }
  },
  components: {
    AppMessage,
    AppForgotPassword,
    AppRegistration,
    AppLogin,
    CtModal,
    AppFooter,
    AppHeader,
    AppResetPassword
  },
  methods: {
    isWhitePage(isWhitePage: boolean) {
      this.whitePage = isWhitePage;
    },
    showLoginModal() {
      this.closeAllModal();
      this.loginModal = true;
    },
    toggleMenu(state: boolean) {
      this.toggle = state;
    },
    showRegistrationModal() {
      this.closeAllModal();
      this.registrationModal = true;
    },
    showForgotPasswordModal() {
      this.closeAllModal();
      this.forgotPasswordModal = true;
    },
    showResetPasswordModal(payload: PayloadInterface) {
      this.closeAllModal();
      this.payload = payload;
      this.resetPasswordModal = true;
    },
    // .......................
    closeAllModal() {
      this.payload = {};
      this.loginModal = false;
      this.registrationModal = false;
      this.forgotPasswordModal = false;
      this.resetPasswordModal = false;
    },
  },
  created() {
    if (window.localStorage.getItem('ct-token')) {
      this.$store.dispatch(ActionTypes.LOAD_CURRENT_USER);
    }
  }
})
export default class App extends Vue {
}
