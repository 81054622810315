import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_navigation_authorized_true = _resolveComponent("app-navigation-authorized-true")!
  const _component_app_navigation_authorized_false = _resolveComponent("app-navigation-authorized-false")!
  const _component_toggle_button = _resolveComponent("toggle-button")!

  return (_openBlock(), _createElementBlock("section", {
    class: "app-navigation-authorized",
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleDesktopNavigation(true))),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleDesktopNavigation(false)))
  }, [
    (_ctx.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_app_navigation_authorized_true, { key: 0 }))
      : (_openBlock(), _createBlock(_component_app_navigation_authorized_false, {
          key: 1,
          onShowLoginModal: _ctx.showLoginModal
        }, null, 8, ["onShowLoginModal"])),
    (_ctx.isMore992AndNotAuth)
      ? (_openBlock(), _createBlock(_component_toggle_button, {
          key: 2,
          onClick: _ctx.toggleMenu,
          class: "app-navigation-authorized__btn"
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 32))
}