<template>
  <div class="app-terms-and-conditions">
    <h1 class="title">Terms and Conditions</h1>
    <p>Survey Drafters Inc. is a registered corporation in Florida and as its business activity is undertaking
      preparation of AutoCad drawings for land surveying companies in Florida through its website namely,
      www.cadtechnicians.com (“<b>website</b>”). By visiting our website and accessing the information, resources,
      services, products and tools we provide, you understand and agree to accept and adhere to the following terms
      and conditions of use, along with the terms and conditions stated in our Privacy Policy. You must not access
      our website if you have not attained 18 years of age.</p>
    <p>All references made as “<b>we</b>”, “<b>our</b>” and “<b>us</b>” in these terms and conditions shall be referring
      to Survey Drafters Inc. and references made as “<b>you</b>” and “<b>your</b>” shall be collectively referring to
      the users of our website and our customers.</p>
    <ol type="1" class="list">
      <li>
        <div>
          <span class="them">Login and Password</span>
          <p>You may access our website and ascertain our services for your business needs of preparing AutoCAD
            drawings. In order to avail our service, you will have to create an account on our website. For creating an
            account, you will have to choose a login id and password. Please maintain confidentiality of your login id
            and password.</p>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Our Services</span>
          <p>We create high-quality AutoCAD drawings for the business needs of land surveying companies. We do not
            undertake surveying services. We are solely into drafting or preparing the AutoCAD drawings. The drawings
            will be based on the data you submit for creation of the AutoCAD drawings. Hence, you must ascertain the
            data as correct and accurate prior to submission. Once, we create and send you an initial AutoCAD drawings
            with the data provided, prior to preparing the final drawings we will undertake any minor variation to the
            drawings to a maximum of 10% variation, at your request. Please note that any requirement for variation over
            10% would be considered as a new order placed for our service of creation of AutoCAD drawings.</p>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Availing of our Services</span>
          <p>You may browse on our website to know the details of our services. However, for availing of our service you
            will have to create an account by providing certain information. Once you have successfully created an
            account, you can start the process of creating a service order. You will have to submit all data on the
            service order in respect of which we will prepare the AutoCAD drawings. Then you will be guided to make the
            payment in respect of the intended service. Upon completion of payment, you will receive a confirmation
            email. We will submit an initial draft of the drawings within 3 days of receipt of data from you. You may
            add or remove any data within the period of 3 days. Prior to preparing the final drawings we will undertake
            any minor variation to the drawings to a maximum of 10% variation.</p>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Payments</span>
          <p>We will use a third party service provider to manage payment processing. We understand that the payment
            processing service provider is not permitted to store, retain, or use your payment account information
            except for the purpose of processing your payment in respect of payment made at our website. However, you
            may refer to the payment terms of the payment processing service provider prior to making the payment. You
            must notify us of any change in your payment account information by e-mailing us at <a href="mailto:office@cadtechnicians.com">office@cadtechnicians.com</a>.</p>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Confirmation of Service Order</span>
          <p>Upon successful payment for the service order, we will send you an email confirming the service order. The
            confirmation email would provide the details of the service ordered, details of the total amount paid and
            the expected date of submission of AutoCAD drawings. Upon placing of order, it cannot be cancelled or
            withdrawn.</p>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Termination</span>
          <ol type="a">
            <li>You may terminate your account held with us at any time without giving any notice. However, we will
              honor your orders placed with us on or before the date of termination of account and we will provide you
              with the service timely.
            </li>
            <li>We shall have a right to terminate your account held with us and block your access to our website if you
              violate any of the terms and conditions contained herein. But, we will honor your orders placed with us on
              or before the date of termination of account and we will provide you with the service timely.
            </li>
            <li>Further, we have a right to terminate your account held with us and block your access to our website if
              you have infringed our ownership or intellectual property rights in respect of our website. In such
              instances, in addition to other legal remedies that are available at law, we shall have a right to obtain
              injunctive relief from a court of law to prevent infringement.
            </li>
          </ol>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Liability and Indemnity</span>
          <ol type="a">
            <li>We do not claim that the use of our website is error free or without problems or interruptions.</li>
            <li>We do not undertake any liability arising from the use of our website by you including any form of
              damages / losses that may be caused and could be construed as consequential, indirect or incidental
              damages including loss of profits and revenue.
            </li>
            <li>You shall be solely liable for uploading any content, videos, files, photos, etc. on the service order
              for placing orders with us. Further, any content downloaded or otherwise obtained through the use of our
              resources or tools is done at your own discretion and risk, and you are solely liable for any damage to
              your computer or other devices and for any loss of data that may result from the download of such content.
            </li>
            <li>You must provide accurate personal information while making an account on our website and you shall be
              solely responsible for any untrue information provided by you.
            </li>
            <li>You are responsible for determining the appropriateness of use and all risks associated with the use of
              our website, including but not limited to risk of errors, unavailability or interruption of operations,
              etc. You are solely responsible for securely maintaining the login and password details pertaining to your
              account on our website.
            </li>
            <li>You agree to defend, indemnify and hold us harmless from and against any and all direct or indirect
              claims, losses, liabilities, costs and expenses (including but not limited to reasonable attorneys’ fees)
              incurred by you and arising from the use of our website. This clause shall survive the termination of your
              account held with us.
            </li>
          </ol>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Disclaimer of Warranties</span>
          <p>Our website is provided on “AS IS” basis without any warranty of any kind. Except as expressly provided
            herein, all express or implied conditions, representations and warranties including without limitation, any
            implied warranty of merchantability, fitness for a particular purpose, non infringement or arising due to
            usage or trade practice are hereby excluded to the extent allowed by applicable law. In no event do we
            warrant that the access to our website is error free or without problems or interruptions. We shall not be
            liable for any direct, indirect, special, consequential or incidental damages arising out of the use or
            inability to use our website, including any errors, viruses, bugs, or other problems of any nature or kind
            whatsoever associated with the use of our website.</p>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Responsible Use and Conduct</span>
          <p>By visiting our website and accessing the information, resources and services we provide, either directly
            or indirectly, you agree to use them only for the purposes permitted by (a) the terms and conditions
            contained herein, (b) applicable laws and regulations and (c) generally accepted online practices and
            guidelines.</p>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Use of our website</span>
          <p>You shall not use the website in any improper or unlawful manner or in breach of any legislation or license
            that applies to you. Your right to use the website is not transferable or assignable. Any password or right
            given to you to obtain information or documents is not transferable or assignable.</p>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Intellectual Property Rights</span>
          <p>All content and materials available on our website, including but not limited to text, graphics, website
            name, code, images and logos are either the intellectual property rights of Survey Drafters Inc. or are
            licensed to us by any third party, and are protected by applicable copyright and trademark laws. You may
            only use such content or materials. The permission to use the content and materials available on the website
            shall not be construed as an assignment of any intellectual property rights in or to such materials or
            content. All our rights are hereby expressly reserved.</p>
          <span>You may view, download and print any materials and content made available to you through the website subject to the following conditions:</span>
          <ol type="a">
            <li>the materials and content may only be used for your personal and non-commercial purposes;</li>
            <li>the materials and content shall not be reproduced or included in any other work or publication in any
              medium;
            </li>
            <li>the materials and content may not be modified or altered in any way;</li>
            <li>the materials and content may not be distributed or sold to any third party;</li>
            <li>you may not remove any copyright or other proprietary notices contained in the material or the
              information.
            </li>
          </ol>
          <p>The website displays our registered trademark and the same shall not be used by you in any manner for any
            reason whatsoever. You shall abide by the Digital Millennium Copyright Act, and other copyright laws
            applicable to the State of Florida. In any event of copyright infringement or suspicion of copyright
            infringement, we may issue a notice to stop or enquire into the infringement / suspected infringement. All
            the structure, designs, work, etc. associated with our website are © Survey Drafters Inc. All rights
            reserved.</p>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Third Party Content</span>
          <p>Third party content may appear on our website or may be accessible via links from the website. We are not
            responsible for and assume no liability for any mistakes, misstatements of law, defamation, omissions,
            falsehood, obscenity or pornography in the statements, opinions, representations or any other form of
            content on the website. You understand that the information and opinions in the third party content
            represent solely the thoughts of the author and is neither endorsed by us nor does it necessarily reflect
            our belief.</p>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Third Party Advertisements and Links to Other Sites</span>
          <p>We have a right to post any authorized advertisements or links to other sites which will be visible to you
            during the use of our website. We shall not be responsible for use of any third party advertisements and
            links to other sites by you. For any deals such as purchase of products, offers, etc. entered into by you in
            respect of such advertisements or links appearing on other sites; you shall be solely responsible for the
            deal including any loss, damage, etc. resulting from such a deal. We do not warrant and are not liable for
            any such loss, damage, etc.</p>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Right to Change the Terms and Conditions</span>
          <p>We reserve the right to change these terms and conditions from time to time without notice. You acknowledge
            and agree that it is your responsibility to review these terms and conditions periodically to familiarize
            yourself with any modifications. Your use of the website after such modifications will constitute
            acknowledgment and agreement of the modified terms and conditions.</p>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Updates Policy</span>
          <p>We may update our website any time and you agree to accept the updated version from time to time.</p>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Governing Law and Dispute Resolution</span>
          <p>These terms and conditions shall be governed by the laws of Florida. Any dispute arising from violation or
            breach of any of the terms and conditions contained herein shall be submitted to the arbitral proceedings to
            be held in Florida, USA as per the prevalent arbitration laws in Florida USA. There shall be a single
            arbitrator appointed by the parties. In the event the parties are unable to arrive at a consensus in
            deciding on the sole arbitrator, the courts in Florida shall have jurisdiction to decide on the sole
            arbitrator.</p>
        </div>
      </li>
      <li>
        <div>
          <span class="them">Contact Information</span>
          <p>If you have any questions or comments about our terms and conditions as outlined above, you may contact us
            at <a href="mailto:office@cadtechnicians.com">office@cadtechnicians.com</a>.</p>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import {Options, Vue} from "vue-class-component";

@Options({
  name: "AppTermsAndConditions",
  components: {},
})
export default class AppTermsAndConditions extends Vue {
}
</script>

<style lang="scss">
.app-terms-and-conditions {
  padding: 20px;

  .title {
    text-align: center;
    font-size: 25px;
    margin: 10px 0;
  }

  .list .them {
    font-size: 15px;
    font-weight: bold;
    text-decoration: underline;
  }
}
@media screen and (min-width: 767px) {
  .app-terms-and-conditions{
    padding: 20px 68px;
  }
}
@media screen and (min-width: 992px) {
  .app-terms-and-conditions {
    padding: 20px 100px;
  }
}
@media screen and (min-width: 1400px) {
  .app-terms-and-conditions{
    padding: 20px 125px;
  }
}
@media screen and (min-width: 1920px) {
  .app-terms-and-conditions{
    padding: 35px 8.2% 10px;
  }
}
</style>
