import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-client-image" }
const _hoisted_2 = ["src", "alt", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.client.logo,
      alt: _ctx.client.name,
      title: _ctx.client.name
    }, null, 8, _hoisted_2)
  ]))
}