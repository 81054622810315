import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "app-navigation-guest"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_menu = _resolveComponent("main-menu")!

  return (!_ctx.isLoggedIn)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_main_menu)
      ]))
    : _createCommentVNode("", true)
}