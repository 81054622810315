import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/image/close.svg'


const _hoisted_1 = { class: "toggle-menu__header" }
const _hoisted_2 = { class: "toggle-menu__btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_logo = _resolveComponent("app-logo")!
  const _component_user_menu = _resolveComponent("user-menu")!
  const _component_main_menu = _resolveComponent("main-menu")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["toggle-menu", {'toggle-menu__not-auth': !_ctx.isLoggedIn }])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("header", _hoisted_1, [
        _createVNode(_component_app_logo),
        _createElementVNode("button", _hoisted_2, [
          _createElementVNode("img", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideToggleMenu && _ctx.hideToggleMenu(...args))),
            src: _imports_0,
            alt: "toggle main menu"
          })
        ])
      ]),
      _createElementVNode("main", {
        class: _normalizeClass(["toggle-menu__main", { 'toggle-menu__main-with-user': _ctx.isLoggedIn }])
      }, [
        _createVNode(_component_user_menu, { onHideMenu: _ctx.hideToggleMenu }, null, 8, ["onHideMenu"]),
        _createVNode(_component_main_menu, { onHideMenu: _ctx.hideToggleMenu }, null, 8, ["onHideMenu"])
      ], 2)
    ])
  ], 2))
}