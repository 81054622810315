
import {Options, Vue,} from "vue-class-component";
import {PropType} from 'vue';
import {Feedback} from "@/models/Feedback";

@Options({
  name: "CtSlider",
  data() {
    return {
      isMobile: false,
      isTwoSlides: 1
    };
  },
  props: {
    feedbacks: {
      type: Array as PropType<Array<Feedback>>,
    },
  },
  computed: {
    sortedFeedback(): Array<Feedback> {
      const resultArray: Array<Feedback> = this.feedbacks.sort((feedback1: Feedback, feedback2: Feedback) => {
        return feedback1.id > feedback2.id ? -1 : 1;
      });
      return resultArray;
    },
    countCurrentPosition(): number {
      return this.isMobile ? 1 : 2;
    },
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 767;
      this.isTwoSlides = (window.innerWidth > 1024) ? 2 : 1;
    },
  },
  beforeUnmount() {
    if (typeof window === 'undefined') return;
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, {passive: true});
  }
})
export default class CtSlider extends Vue {
}
