
import {Options, Vue} from "vue-class-component";
import CtButton from "@/components/ui/CtButton.vue";

@Options({
  name: "AppCreateProjectWelcome",
  components: {
    CtButton
  },
  emits: {
    'white-page'(payload: boolean) {
      return payload;
    }
  },
  created() {
    this.$emit('white-page', true);
  }
})
export default class AppCreateProjectWelcome extends Vue {
}
