
import {Options, Vue} from "vue-class-component";
import {Form, Field, ErrorMessage} from 'vee-validate';
import CtButton from "@/components/ui/CtButton.vue";
import {MutationTypes} from "@/store/vuex.types";
import {toSupport} from '@/services/requests/support'; // todo: move to adapter

interface FormState {
  email: string;
  name: string;
  phone: string;
  topic: string;
}

@Options({
  name: "AppFooterQuestionForm",
  data() {
    return {
      questionForm: {
        email: '',
        name: '',
        phone: '',
        topic: ''
      } as FormState
    };
  },
  components: {
    CtButton,
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    validateEmail(value: string) {
      if (!value) {
        return "This field is required";
      }
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return "This field must be a valid email";
      }
      return true;
    },
    validatePhone(value: string) {
      if (!value) {
        return false;
      }
      const regex = /^[0-9+]{5,20}$/g;
      if (!regex.test(value)) {
        return "This field must be a valid phone";
      }
      if (value.length <= 5) {
        return "Phone must contain at least 5 characters";
      }

      return true;
    },
    validateQuestion(value: string) {
      if (!value) {
        return "This field is required";
      }
      if (value.length <= 10) {
        return "Question must contain at least 10 characters";
      }
      return true;
    },
    validateName(value: string) {
      if (!value) {
        return "This field is required";
      }
      if (value.length <= 4) {
        return "Name must contain at least 8 characters";
      }
      return true;
    },
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    async submitQuestion(value: any, {resetForm}: any) {
      await toSupport(this.questionForm);

      this.$store.commit(MutationTypes.SET_ALERT_MESSAGE,
          'Thank you for your question!');

      resetForm();
    },
  }
})
export default class AppFooterQuestionForm extends Vue {
}
