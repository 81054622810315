export enum ProjectStatus{
    NEW_PROJECT = 'NEW PROJECTS', // 8
    CHECKING = 'CHECKING', // 15
    IN_DEVELOPMENT = 'IN DEVELOPMENT', // 2
    COMPLETED = 'COMPLETED', // 16
    CANCELLATION = 'CANCELLATION', // 17
    FROZEN = 'FROZEN' // 18
}

export enum ProjectStatusNumber {
    NEW_PROJECT = 8,
    CHECKING = 15,
    IN_DEVELOPMENT = 2,
    COMPLETED = 16,
    CANCELLATION = 17,
    FROZEN = 18,
}

export const projectStatusNumbers: Record<ProjectStatus, ProjectStatusNumber> = {
    [ProjectStatus.NEW_PROJECT]: ProjectStatusNumber.NEW_PROJECT,
    [ProjectStatus.CHECKING]: ProjectStatusNumber.CHECKING,
    [ProjectStatus.IN_DEVELOPMENT]: ProjectStatusNumber.IN_DEVELOPMENT,
    [ProjectStatus.COMPLETED]: ProjectStatusNumber.COMPLETED,
    [ProjectStatus.CANCELLATION]: ProjectStatusNumber.CANCELLATION,
    [ProjectStatus.FROZEN]: ProjectStatusNumber.FROZEN,
};
