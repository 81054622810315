import { ActionTree, Commit } from 'vuex';
import { State } from './state';
import {ActionTypes} from "@/store/vuex.types";

import {CurrentUserActions, currentUserActions} from "@/store/currrentUser/actions";
import {CurrentProjectActions, currentProjectActions} from "@/store/currentProject/actions";

export type Actions<State> = CurrentUserActions<State> & CurrentProjectActions<State>; //&

export const actions: ActionTree<State, State> & Actions<State> = {
    ...currentUserActions,
    ...currentProjectActions
};
