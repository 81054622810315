
import {Options, Vue} from "vue-class-component";
import AppContactHeader from "@/components/pages/contact/AppContactHeader.vue";
import AppFooterQuestion from "@/components/global/footer/AppFooterQuestion.vue";

@Options({
  name: "AppContact",
  emits: {
    'white-page'(payload: boolean){
      return true;
    }
  },
  components: {
    AppFooterQuestion,
    AppContactHeader
  },
  created(){
    this.$emit('white-page', true);
  }
})
export default class AppContact extends Vue {
}
