
import {Options, Vue} from "vue-class-component";
import CtButton from "@/components/ui/CtButton.vue";


@Options({
  name: "AppHomeAbout",
  components: {
    CtButton
  },
  methods: {
    handleTryIt() {
      this.$router.push("/about-us");
    }
  }
})
export default class AppHomeAbout extends Vue {
}
