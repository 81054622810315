
import {Options, Vue} from "vue-class-component";
import AppNavigationAuthorized from "@/components/global/header/AppNavigationAuthorized.vue";
import AppNavigationGuest from "@/components/global/header/AppNavigationGuest.vue";
import ToggleMenu from "@/components/global/header/ToggleMenu.vue";

const maxWidthForMobileMenuView = 992;
@Options({
  name: "AppNavigation",
  emits: ["show-login-modal", 'hide-menu', 'toggle-menu'],
  components: {ToggleMenu, AppNavigationAuthorized, AppNavigationGuest},
  data() {
    return {
      desktopMenu: false,
      isMoreThen992px: true
    };
  },
  methods: {
    showLoginModal() {
      this.$emit('show-login-modal');
    },
    toggleMenu() {
      this.$emit('toggle-menu');
    },
    hideMenu(){
      this.desktopMenu = false;
      this.$emit('hide-menu');
    },
    toggleDesktopMenu (value: boolean) {
      this.isMoreThen992px ? this.desktopMenu = value : this.desktopMenu = false;
    },
    onResize() {
      if(window.innerWidth >= maxWidthForMobileMenuView) this.isMoreThen992px = true;
      else this.isMoreThen992px = false;
    },
  },
  beforeUnmount() {
    if (typeof window === 'undefined') return;
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, {passive: true});
  }
})
export default class AppNavigation extends Vue {}
