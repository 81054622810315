import {instanceAxios} from "@/services/axiosInit";
import {Response} from "@/models/Response";
import store from "@/store";
import {MutationTypes} from "@/store/vuex.types";

interface PayloadInterface{
    [key: string]: string
}

async function toSupport(payload: PayloadInterface) {
    try{
        const response = await instanceAxios.post('/api/support', payload) as Response;
        return response.data;
    } catch(e){
        store.commit(MutationTypes.SET_ALERT_MESSAGE, 'Something goes wrong, please try again later.');
    }
}

export  {
    toSupport
};
