
import {Options, Vue} from "vue-class-component";
import {mapGetters} from "vuex";
import { InfoCircleOutlined } from '@ant-design/icons-vue';


@Options({
  name: "AppProfileEmail",
  components: {
    InfoCircleOutlined
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  emits: {
    'white-page'(payload: boolean) {
      return true;
    }
  },
  created() {
    this.$emit('white-page', true);
  }
})
export default class AppProfileEmail extends Vue {
}
