
import {Options, Vue} from "vue-class-component";
import {mapGetters} from "vuex";
import {ActionTypes} from "@/store/vuex.types";

@Options({
  name: "UserMenu",
  components: {},
  emits: ['hide-menu'],
  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn']),
  },
  methods: {
    isClicked() {
      this.$emit('hide-menu');
    },
    logout (){
      this.$store.dispatch(ActionTypes.LOGOUT);
      this.$emit('hide-menu');
    }
  }
})
export default class UserMenu extends Vue {
}
