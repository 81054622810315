
import {Options, Vue} from "vue-class-component";
import AppProjectsTable from "@/components/pages/project/AppProjectsTable.vue";
import {Project} from "@/models/Project";
import {ProjectStatus} from "@/models/ProjectStatus";

import {projectGetAll} from '@/services/adapters/projectsAdapter';
import { ProjectQueries } from '@/models/ProjectQueries';
import { getProjectStatusByNumber, getProjectStatusNumber } from '@/utils/project';

@Options({
  name: "AppProjects",
  components: {
    AppProjectsTable
  },
  data(){
    return {
      projects: [] as Project[],
      perPage: 15,
      totalProjects: 0,
      lastPage: 1,
      ProjectStatus,
    };
  },
  emits: {
    'white-page'(payload: boolean) {
      return true;
    }
  },
  computed: {
    isQueryEmpty() {
      return Object.keys(this.$route.query).length === 0;
    },
    page() {
      return this.$route.query.page ? Number(this.$route.query.page) : 1;
    },
    search() {
      return this.$route.query.search ?? '';
    },
    status() {
      return getProjectStatusByNumber(this.$route.query.status) ?? 'ALL';
    }
  },
  methods: {
    async getProjects() {
      const params: ProjectQueries = {
        name: this.search,
        status: this.status === 'ALL' ? undefined : getProjectStatusNumber(this.status),
        page: this.page
      };

      const data = await projectGetAll(params);
      if (!data) {
        return null;
      }
      this.totalProjects = data.total;
      this.perPage = data.per_page;
      this.lastPage = data.last_page;

      return data.projects;
    },
    changeFilters(params: any) {
      const formattedParams = {
        ...this.$route.query,
        ...params,
        ...(params.status ? {
          status: params.status === 'ALL' ? undefined : getProjectStatusNumber(params.status)
        } : {})
      };
      for (let key of Object.keys(formattedParams)) {
        if (!formattedParams[key]) {
          delete formattedParams[key];
        }
      }
      this.$router.push({
        ...this.$route,
        query: formattedParams
      });
    },
  },
  watch: {
    '$route.query': {
      async handler() {
        const projects = await this.getProjects();
        if (projects) {
          this.projects = projects;
        }
      },
      deep: true
    }
  },
  created() {
    this.$emit('white-page', true);
  },
  async mounted(){
    const projects = await this.getProjects();
    if (projects) {
      this.projects = projects;
      if (projects.length === 0 && this.isQueryEmpty) {
        this.$router.push('/create-project-welcome');
      }
    }
  }
})
export default class AppProjects extends Vue {
}
