
import {Options, Vue} from "vue-class-component";
import env from '@/utils/env';

const supportEmail = env.SUPPORT_EMAIL;

@Options({
  name: "AppContactHeader",
  components: {},
  data: () => ({
    supportEmail,
  })
})
export default class AppContactHeader extends Vue {
}
