
import {Options, Vue} from "vue-class-component";
import {Form, Field, ErrorMessage} from 'vee-validate';
import CtButton from "@/components/ui/CtButton.vue";
import {processResetPassword} from "@/services/requests/auth";
import {MutationTypes} from "@/store/vuex.types"; // todo: move to adapter

@Options({
  name: "AppRegistration",
  emits: ['show-reset-password-modal', 'modal-close'],
  props: {
    payload: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      password: '',
      password_confirmation: '',
      error: ''
    };
  },
  components: {
    CtButton,
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    async changePassword() {
      const data = {
        ...this.payload,
        password: this.password,
        password_confirmation: this.password_confirmation
      };

      await processResetPassword(data);

      this.$store.commit(MutationTypes.SET_ALERT_MESSAGE, 'Your password was successfully changed');

      this.$emit('modal-close');
    },
    validatePassword(value: string) {
      if (!value) {
        return "This field is required";
      }
      if (value.length < 8) {
        return "Password must contain at least 8 characters";
      }
      return true;
    },
    validateRepeatPassword(value: string) {
      if (!value) {
        return "This field is required";
      }
      if (value.length < 8) {
        return "Password must contain at least 8 characters";
      }
      if (value != this.password) {
        return "Repeat password and password does not match";
      }
      return true;
    },
  },
})
export default class AppRegistration extends Vue {
}
