import {State} from "@/store/state";
import {MutationTypes} from "@/store/vuex.types";

export type ModalMessageMutations<State> =  {
    [MutationTypes.SET_ALERT_MESSAGE]:
        (state: State, payload: string) => void;
    [MutationTypes.ON_SPINNER]:
        (state: State) => void;
    [MutationTypes.OFF_SPINNER]:
        (state: State) => void;

}

export const modalMessageMutations: ModalMessageMutations<State> = {
    [MutationTypes.SET_ALERT_MESSAGE](state: State, payload: string) {
        state.alertMessage = payload;
    },
    [MutationTypes.ON_SPINNER](state: State) {
        state.spinner = true;
    },
    [MutationTypes.OFF_SPINNER](state: State) {
        state.spinner = false;
    },
};

