import {instanceAxios} from "@/services/axiosInit";

import {Response} from "@/models/Response";
import { Project, ProjectsResponse } from '@/models/Project';
import { ProjectQueries } from '@/models/ProjectQueries';
import { Message } from '@/models/Message';

async function projectCreate(createProject: FormData) {
  const response = await instanceAxios.post('/api/projects', createProject) as Response;
  return response.data;
}

async function projectGetAll(params: ProjectQueries = { page: 2 }): Promise<Response<ProjectsResponse>> {
  const response = await instanceAxios.get<Response<ProjectsResponse>>('/api/projects', {
    params
  });
  return response.data;
}

async function projectGetById(id: number): Promise<Response<Project>> {
  const response = await instanceAxios.get<Response<Project>>(`/api/projects/${id}`);
  return response.data;
}

async function projectMessageListGetById(id: number): Promise<Response<Message[]>> {
  const response = await instanceAxios.get<Response<Message[]>>(`/api/projects/${id}/messages`);
  return response.data;
}

async function projectMessageAdd(id: number, params: Record<string, unknown>): Promise<Response<{ id: number }>> {
  const response = await instanceAxios.post<Response<{ id: number }>>(`/api/projects/${id}/messages`, params);
  return response.data;
}

async function projectMessageSeen(id: number): Promise<Response<[]>> {
  const response = await instanceAxios.post<Response<[]>>(`/api/projects/${id}/messages/seen`);
  return response.data;
}

async function projectFilesList(id: number) {
  const response = await instanceAxios.get(`/api/projects/${id}/files`) as Response;
  return response.data;
}

async function projectFilesUpload(id: number, filesJson: Array<Record<string, unknown>>) {
  const response = await instanceAxios.post(`/api/projects/${id}/files`, {
    params: { s3_files: filesJson }
  }) as Response;
  return response.data;
}

async function projectFilesDownload(id: number, filesArray: Array<number>) {
  const response = await instanceAxios.get(`/api/projects/${id}/files/download`, {
    params: { files: filesArray }
  }) as Response;
  return response.data;
}

async function projectFilesRemove(id: number, files: Array<number>) {
  const response = await instanceAxios.delete(`/api/projects/${id}/files`, {
    params: { files: files }
  }) as Response;
  return response.data;
}

export {
  projectCreate,
  projectGetAll,
  projectGetById,
  projectMessageListGetById,
  projectMessageAdd,
  projectMessageSeen,
  projectFilesList,
  projectFilesUpload,
  projectFilesDownload,
  projectFilesRemove,
};
