import axios, {Axios, AxiosAdapter, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import store from "@/store";
import router from "@/router";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
//import MockAdapter, { RequestHandler } from "axios-mock-adapter";
import env from "@/utils/env";
//import authMock from "@/services/mock/authMock.ts.mock";
//import projectsMock from "@/services/mock/projectsMock.ts.mock";
import {MutationTypes} from "@/store/vuex.types";


const urlPath = env.URL;

const config: AxiosRequestConfig = {
    baseURL: urlPath,
};


// eslint-disable-next-line prefer-const
const instanceAxios = axios.create(config);

instanceAxios.interceptors.request.use(
    (config) => {
        store.commit(MutationTypes.OFF_SPINNER);
        if(window.localStorage.getItem("ct-token")){
            config.headers = {
                Authorization: `Bearer ${window.localStorage.getItem("ct-token")}`
            };
        }

        const check = config.url?.endsWith('files') ||
            config.url?.endsWith('/files/download') ||
            config.url?.endsWith('messages') ||
            config.url?.endsWith('messages/seen');

        if(!check){
            store.commit(MutationTypes.ON_SPINNER);
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instanceAxios.interceptors.response.use(
    (response) => {
        store.commit(MutationTypes.OFF_SPINNER);
        return response;
    },
    (error) => {
        store.commit(MutationTypes.OFF_SPINNER);

        if (error.response?.status === 400) {
            store.commit(MutationTypes.SET_ALERT_MESSAGE, error.response.data.message);
        }
        if (error.response?.status === 401){
            window.localStorage.removeItem('ct-token');
            store.commit(MutationTypes.SET_CURRENT_USER, {});
        }
        if (error.response?.status === 404) {
            router.push({ name: "AppNotFoundPage"});
            // store.commit(MutationTypes.SET_ALERT_MESSAGE, error.response.data.message ? error.response.data.message: "Not Found");
        }
        if (error.response?.status >= 500) {
            store.commit(MutationTypes.SET_ALERT_MESSAGE, error);
        }

        return Promise.reject(error);
    }
);

//const mockAdapter = new MockAdapter(instanceAxios, { delayResponse: 2000 });
// authMock();
// projectsMock();

export {
    instanceAxios,
    //mockAdapter
};




