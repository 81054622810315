import {State} from "@/store/state";
import { CurrentUser } from "@/models/CurrentUser";

export type Getters = {
    currentUser(state: State): CurrentUser;
    isLoggedIn(state: State): boolean;
}

export const getters: Getters ={
    currentUser: (state: State) => {
        return state.currentUser;
    },
    isLoggedIn: (state: State) => {
        return !!state.currentUser.id;
    },
};
