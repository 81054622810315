
import {Options, Vue} from "vue-class-component";

@Options({
  name: "ToggleButton",
  emits: ["toggle-menu"],
  components: {},
  computed: {
  },
  methods: {
    toggleMenu() {
      this.$emit('toggle-menu');
    },
  },
})
export default class ToggleButton extends Vue {}
