
import {Options, Vue} from "vue-class-component";
import {mapGetters} from "vuex";
import AppNavigationAuthorizedTrue from "@/components/global/header/AppNavigationAuthorizedTrue.vue";
import AppNavigationAuthorizedFalse from "@/components/global/header/AppNavigationAuthorizedFalse.vue";
import ToggleButton from "@/components/pages/home/ToggleButton.vue";


const maxWidthForMobileMenuView = 992;
@Options({
  name: "AppNavigationAuthorized",
  emits: ["show-login-modal", "toggle-menu", 'toggle-desktop-menu'],
  components: {ToggleButton, AppNavigationAuthorizedTrue, AppNavigationAuthorizedFalse},
  data() {
    return {
      isMoreThen992px: false,
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn']),
    isMore992AndNotAuth () {
      return !(this.isMoreThen992px && !this.isLoggedIn);
    }
  },
  methods: {
    showLoginModal() {
      this.$emit('show-login-modal');
    },
    toggleMenu() {
      this.$emit('toggle-menu');
    },
    toggleDesktopNavigation (value: boolean) {
      if(this.isLoggedIn) {
        this.$emit('toggle-desktop-menu', value);
      }
    },
    onResize() {
      if(window.innerWidth >= maxWidthForMobileMenuView) this.isMoreThen992px = true;
      else this.isMoreThen992px = false;
    },
  },
  beforeUnmount() {
    if (typeof window === 'undefined') return;
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, {passive: true});
  }
})
export default class AppNavigationAuthorized extends Vue {}
