
import {Options, Vue} from "vue-class-component";

@Options({
  name: "MainMenu",
  emits: ['hide-menu'],
  components: {},
  computed: {},
  methods: {
    isClicked() {
      this.$emit('hide-menu');
    },
  },
})
export default class MainMenu extends Vue {
}
