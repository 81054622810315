import {State} from "@/store/state";
import {MutationTypes} from "@/store/vuex.types";
import { CurrentUser } from "@/models/CurrentUser";

export type CurrentUserMutations<State> =  {
    [MutationTypes.SET_CURRENT_USER]:
        (state: State, payload: CurrentUser) => void;
}

export const currentUserMutation: CurrentUserMutations<State> = {
    [MutationTypes.SET_CURRENT_USER](state: State, payload: CurrentUser) {
        state.currentUser = payload;
    },
};
