
import {Options, Vue} from "vue-class-component";
import AppFooterFeedback from "@/components/global/footer/AppFooterFeedback.vue";
import AppFooterClients from "@/components/global/footer/AppFooterClients.vue";
import {Client} from "@/models/Client";
import env from '@/utils/env';

const supportEmail = env.SUPPORT_EMAIL;

@Options({
  name: "AppFooter",
  components: {
    AppFooterClients,
    AppFooterFeedback
  },
  emits: ['show-registration-modal'],
  data() {
    return {
      supportEmail,
      clients: [
        {
          name: 'Some client 1',
          logo: 'https://cdn.worldvectorlogo.com/logos/unity-69.svg'
        },
        {
          name: 'Some client 2',
          logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Xbox_Logo.svg/1200px-Xbox_Logo.svg.png'
        },
        {
          name: 'Some client 3',
          logo: 'https://cdn.worldvectorlogo.com/logos/unity-69.svg'
        },
        {
          name: 'Some client 4',
          logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Xbox_Logo.svg/1200px-Xbox_Logo.svg.png'
        },
        {
          name: 'Some client 5',
          logo: 'https://cdn.worldvectorlogo.com/logos/unity-69.svg'
        },
        {
          name: 'Some client 6',
          logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Xbox_Logo.svg/1200px-Xbox_Logo.svg.png'
        },
        {
          name: 'Some client 7',
          logo: 'https://cdn.worldvectorlogo.com/logos/unity-69.svg'
        }
      ] as Array<Client>
    };
  }
})
export default class AppFooter extends Vue {
}
