
import {Options, Vue} from "vue-class-component";
import AppHomeAbout from "@/components/pages/home/AppHomeAbout.vue";
import AppHomeHowItWorks from "@/components/pages/home/AppHomeHowItWorks.vue";
import AppHomeContent from "@/components/pages/home/AppHomeContent.vue";
import {authRegistrationConfirm} from "@/services/adapters/authAdapter";
import {MutationTypes} from "@/store/vuex.types";
import AppFooterQuestion from "@/components/global/footer/AppFooterQuestion.vue";
import AppFooterReady from "@/components/global/footer/AppFooterReady.vue";

interface Messages {
  [key: string]: string;
}

const messages: Messages = {
  'Email.Confirmed': 'Your email was successfully confirmed',
  'Email.Rejected': 'Something happens when trying to confirm you email. Please contact to our support'
};

@Options({
  components: {
    AppFooterQuestion,
    AppFooterReady,
    AppHomeContent,
    AppHomeHowItWorks,
    AppHomeAbout
  },
  emits: {
    'white-page'(payload: boolean) {
      return true;
    },
    'show-registration-modal': null,
    'show-login-modal': null,
  },
  created() {
    this.$emit('white-page', false);
  },
  async mounted() {
    if (this.$route.query['reset-password']) {
      const payload = {
        token: this.$route.query['reset-password'],
        email: this.$route.query['reset-email']
      };
      this.$emit('show-reset-password-modal', payload);
    }
    if (this.$route.query.confirm) {
      const status = await authRegistrationConfirm(this.$route.query.confirm);
      if (status) {
        this.$emit('show-login-modal');
        this.$store.commit(MutationTypes.SET_ALERT_MESSAGE, messages['Email.Confirmed']);
      } else {
        this.$store.commit(MutationTypes.SET_ALERT_MESSAGE, messages['Email.Rejected']);
      }
      this.$router.push('/');
    }
  }
})
export default class AppHome extends Vue {
}
