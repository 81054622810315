
import {Options, Vue} from "vue-class-component";
import {ProjectStatus} from "@/models/ProjectStatus";
import { getProjectStatusColor } from "@/utils/project";
import { PropType } from 'vue';

@Options({
  name: "AppProjectsTableHeader",
  props: {
    status: {
      type: String as PropType<ProjectStatus | 'ALL'>
    },
    search: {
      type: String
    }
  },
  data() {
    return {
      searchValue: '',
      ProjectStatus
    };
  },
  emits: [
    'change:status',
    'change:search'
  ],
  computed: {
    textColorSelect(): string {
      return getProjectStatusColor(this.status);
    }
  },
  methods: {
    changeFilter(status: ProjectStatus | 'ALL') {
      this.$emit('change:status', status);
    },
    handleSearch(value: string) {
      this.$emit('change:search', value.trim());
    },
    resetSearch() {
      this.searchValue = '';
      this.handleSearch('');
    }
  },
  created() {
    this.searchValue = this.search;
  }
},
)

export default class AppProjectsTableHeader extends Vue {
}
