import {State} from "@/store/state";
import {MutationTypes} from "@/store/vuex.types";
import {Project} from "@/models/Project";
import {Message} from "@/models/Message";
import {ProjectFile} from "@/models/ProjectFile";

export type CurrentProjectMutations<State> =  {
    [MutationTypes.CURRENT_PROJECT]:
        (state: State, payload: Project | null) => void;

    [MutationTypes.CURRENT_CHAT_MESSAGES]:
        (state: State, payload: Array<Message> | null) => void;

    [MutationTypes.CURRENT_CHAT_FILES]:
        (state: State, payload: Array<ProjectFile> | null) => void;

    [MutationTypes.FETCHING_CURRENT_CHAT_FILES]:
        (state: State, payload: boolean) => void
}

export const currentProjectMutation: CurrentProjectMutations<State> = {
    [MutationTypes.CURRENT_PROJECT](state: State, payload: Project | null) {
        state.currentProject = payload;
    },
    [MutationTypes.CURRENT_CHAT_MESSAGES] (state: State, payload: Array<Message> | null) {
        state.currentMessages = payload;
    },
    [MutationTypes.CURRENT_CHAT_FILES] (state: State, payload: Array<ProjectFile> | null) {
        state.currentFiles = payload;
    },
    [MutationTypes.FETCHING_CURRENT_CHAT_FILES] (state: State, payload: boolean) {
        state.fetchingFiles = payload;
    }
};
