import { ProjectStatus, ProjectStatusNumber, projectStatusNumbers } from '@/models/ProjectStatus';

export function getProjectStatusNumber(status: ProjectStatus): ProjectStatusNumber {
  return projectStatusNumbers[status];
}

export function getProjectStatusByNumber(statusNumber: ProjectStatusNumber): ProjectStatus | undefined {
  for (const [key, value] of Object.entries(projectStatusNumbers)) {
    if (value === Number(statusNumber)) {
      return key as ProjectStatus;
    }
  }
}

const projectStatusColors: Record<ProjectStatus, string> = {
  [ProjectStatus.NEW_PROJECT]: '#00AF89',
  [ProjectStatus.CHECKING]: '#098568',
  [ProjectStatus.IN_DEVELOPMENT]: '#025F49',
  [ProjectStatus.COMPLETED]: '#00372A',
  [ProjectStatus.CANCELLATION]: '#8D8D8D',
  [ProjectStatus.FROZEN]: '#FF1616'
};

export function getProjectStatusColor(status: ProjectStatus): string {
  return projectStatusColors[status] || '#9B9B9B';
}
