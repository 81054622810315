
import {Options, Vue} from "vue-class-component";
import {mapGetters} from "vuex";
import MainMenu from "@/components/global/header/MainMenu.vue";

@Options({
  name: "AppNavigationGuest",
  emits: ["show-login-modal"],
  components: {MainMenu},
  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn']),
  },
  methods: {
    showLoginModal() {
      this.$emit('show-login-modal');
    },
  },
})
export default class AppNavigationGuest extends Vue {
}
